import React from "react";
import "style.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ComponentRenderer from "ComponentRenderer";
import MainPage from "MainPage";
import TermsAndConditions from "TermsAndConditions";
import Contact from "Contact";
import Pricing from "Pricing";
import About from "About";

import { MantineProvider } from "@mantine/core";
function App() {
  return (
    <MantineProvider
      withGlobalStyles
      withNormalizeCSS
      theme={{
        breakpoints: {
          xs: 500,
          sm: 800,
          md: 1000,
          lg: 1200,
          xl: 1400,
        },
      }}
    >
      <Router>
        <Routes>
          <Route
            path="/components/:type/:name"
            element={<ComponentRenderer />}
          />
          <Route path="/contact" element={<Contact />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/" element={<MainPage />} />
          <Route path="/terms" element={<TermsAndConditions />} />
          <Route path="/about" element={<About />} />
        </Routes>
      </Router>{" "}
    </MantineProvider>
  );
}

export default App;
