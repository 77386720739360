/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import styled from "styled-components"; //eslint-disable-line
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-9.svg";
import { ContentWithPaddingXl, Container } from "components/misc/Layouts";

const PrimaryBackgroundContainer = tw.div`relative rounded-lg bg-primary-500 py-20 lg:py-24`;
const Row = tw.div`relative z-10 mx-auto flex max-w-screen-lg flex-col items-center px-8 text-center lg:(flex-row text-left)`;

const ColumnContainer = tw.div`max-w-lg lg:w-1/2`;
const TextContainer = tw(ColumnContainer)``;
const Text = tw.h5`font-bold text-gray-100 text-2xl sm:text-3xl`;

const LinksContainer = tw(
  ColumnContainer
)`mt-6 flex flex-col justify-center sm:flex-row lg:(mt-0 justify-end)`;

const Link = tw.a`mt-4 w-full rounded-full border border-transparent px-6 py-3 font-bold text-sm tracking-wide transition duration-300 first:mt-0 focus:(shadow-outline outline-none) sm:(mt-0 mr-8 w-auto px-8 py-4 text-base last:mr-0) lg:(px-10 py-5)`;
const PrimaryLink = tw(
  Link
)`bg-red-500 text-gray-100 shadow-lg hocus:(bg-red-600 text-gray-200)`;

const SecondaryLink = tw(
  Link
)`border-gray-500 text-gray-100 hover:(border-primary-500 bg-gray-100 text-primary-500)`;

const DecoratorBlobContainer = tw.div`absolute inset-0 overflow-hidden rounded-lg`;
const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`absolute bottom-0 left-0 h-80 w-80 -translate-x-20 translate-y-32 transform text-primary-700 opacity-50`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob1
)`absolute top-0 right-0 h-80 w-80 translate-x-20 -translate-y-64 transform text-primary-700 opacity-50`;
export default ({
  text = "Developers all over the world are happily using Treact.",
  primaryLinkText = "Get Started",
  primaryLinkUrl = "http://timerse.com",
  secondaryLinkText = "Contact Us",
  secondaryLinkUrl = "http://google.com",
  pushDownFooter = true,
}) => {
  return (
    <Container css={pushDownFooter && tw`mb-20 lg:mb-24`}>
      <ContentWithPaddingXl>
        <PrimaryBackgroundContainer>
          <Row>
            <TextContainer>
              <Text>{text}</Text>
            </TextContainer>
            <LinksContainer>
              <PrimaryLink href={primaryLinkUrl}>{primaryLinkText}</PrimaryLink>
              <SecondaryLink href={secondaryLinkUrl}>
                {secondaryLinkText}
              </SecondaryLink>
            </LinksContainer>
          </Row>
          <DecoratorBlobContainer>
            <DecoratorBlob1 />
            <DecoratorBlob2 />
          </DecoratorBlobContainer>
        </PrimaryBackgroundContainer>
      </ContentWithPaddingXl>
    </Container>
  );
};
