/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading } from "components/misc/Headings.js";
import { PrimaryLink as PrimaryLinkBase } from "components/misc/Links.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";
import { ReactComponent as TimeIcon } from "feather-icons/dist/icons/clock.svg";
import { ReactComponent as TrendingIcon } from "feather-icons/dist/icons/trending-up.svg";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-icon.svg";

const Container = tw.div`relative`;
const Content = tw.div`mx-auto max-w-screen-xl py-20 lg:py-24`;

const ThreeColumn = tw.div`flex flex-wrap`;
const Column = tw.div``;
const HeadingColumn = tw(Column)`w-full xl:w-1/3`;
const CardColumn = tw(Column)`mt-16 w-full md:w-1/2 xl:(mt-0 w-1/3)`;

const HeadingInfoContainer = tw.div`mx-auto max-w-lg text-center xl:(mx-0 max-w-none text-left)`;
const HeadingTitle = tw(SectionHeading)`leading-tight xl:text-left`;
const HeadingDescription = tw.p`mt-8 font-medium text-sm text-secondary-100 leading-relaxed md:text-base lg:text-lg`;
const PrimaryLink = styled(PrimaryLinkBase)`
  ${tw`mt-8 inline-flex items-center justify-center text-lg xl:justify-start`}
  svg {
    ${tw`ml-2 h-5 w-5`}
  }
`;

const Card = tw.div`mx-auto max-w-sm md:max-w-xs lg:max-w-sm xl:(mx-0 ml-auto max-w-xs)`;
const CardImage = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`h-80 rounded bg-cover bg-center`,
]);

const CardText = tw.div`mt-4`;

const CardHeader = tw.div`flex items-center justify-between`;
const CardType = tw.div`font-bold text-primary-500 text-lg`;
const CardPrice = tw.div`font-semibold text-sm text-gray-600`;
const CardPriceAmount = tw.span`font-bold text-gray-800 text-lg`;

const CardTitle = tw.h5`mt-4 font-bold text-xl`;

const CardMeta = styled.div`
  ${tw`flex flex-row flex-wrap justify-between font-semibold text-gray-600 text-xs uppercase tracking-wide sm:items-center`}
`;

const CardMetaFeature = styled.div`
  ${tw`mt-4 flex items-center`}
  svg {
    ${tw`mr-1 h-5 w-5`}
  }
`;
const CardAction = tw(PrimaryButtonBase)`mt-8 w-full`;

export default () => {
  const cards = [
    {
      imageSrc:
        "https://images.unsplash.com/photo-1553194587-b010d08c6c56?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80",
      type: "Beachfront",
      pricePerDay: "$99",
      title: "A Trip to the Bahamas and the Carribean Ocean",
      trendingText: "Trending",
      durationText: "7 Days Tour",
      locationText: "Africa",
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1584200186925-87fa8f93be9b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80",
      type: "Cruise",
      pricePerDay: "$169",
      title: "Cruise to the Mariana Trench and the Phillipines",
      trendingText: "Trending",
      durationText: "15 Days Tour",
      locationText: "Australia",
    },
  ];
  return (
    <Container>
      <Content>
        <ThreeColumn>
          <HeadingColumn>
            <HeadingInfoContainer>
              <HeadingTitle>Trending Tours</HeadingTitle>
              <HeadingDescription>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua enim
                ad minim veniam.
              </HeadingDescription>
              <PrimaryLink>
                View All Tours <ArrowRightIcon />
              </PrimaryLink>
            </HeadingInfoContainer>
          </HeadingColumn>
          {cards.map((card, index) => (
            <CardColumn key={index}>
              <Card>
                <CardImage imageSrc={card.imageSrc} />
                <CardText>
                  <CardHeader>
                    <CardType>{card.type}</CardType>
                    <CardPrice>
                      <CardPriceAmount>{card.pricePerDay}</CardPriceAmount> per
                      day
                    </CardPrice>
                  </CardHeader>
                  <CardTitle>{card.title}</CardTitle>
                  <CardMeta>
                    <CardMetaFeature>
                      <TrendingIcon /> {card.trendingText}
                    </CardMetaFeature>
                    <CardMetaFeature>
                      <TimeIcon /> {card.durationText}
                    </CardMetaFeature>
                    <CardMetaFeature>
                      <LocationIcon /> {card.locationText}
                    </CardMetaFeature>
                  </CardMeta>
                  <CardAction>Book Now</CardAction>
                </CardText>
              </Card>
            </CardColumn>
          ))}
        </ThreeColumn>
      </Content>
    </Container>
  );
};
