/* eslint-disable no-unused-vars */
import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import "style.css";

import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";
import Hero from "components/hero/BackgroundAsImage";
const FeatureContainer = tw.div`bg-gray-primary`;
const Subheading = tw.span`text-sm uppercase tracking-wider`;
const AboutHero = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover h-[40vh]`}
  background-image: url("https://images.unsplash.com/photo-1522071901873-411886a10004?ixlib=rb-1.2.1&auto=format&fit=crop&w=1920&q=80");
`;
function About() {
  return (
    <FeatureContainer>
      <Header />
      <AnimationRevealPage>
        <Hero heading="All About" />

        <MainFeature1
          subheading={<Subheading>About WeBuilderX</Subheading>}
          heading="We are a modern development agency."
          buttonRounded={false}
          primaryButtonText="Contact Us"
          primaryButtonUrl="/contact"
          imageBorder={true}
          imageSrc="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
          description="We exist to help make it easier, and more feasible for more people, to start small businesses/startups. So we have priced and specifically tailored our offerings to meet the needs of early stage businesses, startups, inventors, dreamers and collaborators. While we know just about anyone can make a website on their own with current technology, we also know that for most business owners and entrepreneurs just starting out (especially those without technical cofounders), it makes more sense and makes for a much faster, cleaner process, to simply work with experts who turn out an excellent product repeatedly, quickly and effectively, for a fraction of the cost of most web design agencies and marketing agencies. Working with us also means that you can get started right away, for less. We’re small, quick moving, and we take a personally vested interest in each client that we work with. This means that we do more for our clients, and we do it for less."
        />
        <MainFeature1
          subheading={<Subheading>Our Vision</Subheading>}
          heading="We aim to help you succeed."
          buttonRounded={false}
          imageBorder={true}
          imageVertical={true}
          imageSrc="https://images.unsplash.com/photo-1600880292203-757bb62b4baf?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
          textOnLeft={false}
          primaryButtonUrl="/pricing"
          primaryButtonText="Purchase Now"
          description="We don’t just create your website and see you on your way, we look for ways to give you an advantage over your competitors. To see your business through the eyes of your customers, help you tighten your feedback loop, increase conversations and traffic and help you actually accomplish your goals and succeed. Once we help you do that, we know that we will be able to help you with more and more as we go along, and will be able to grow together. We range from offering inexpensive services that are meant to allow small businesses and startup founders to keep more money in their banks, and to get more coming in faster/earlier by allowing our customers to start with very inexpensive, effective services that can then build and grow with your business quickly without much strain, stress or time between now and then. Our goal is not only to help accelerate your growth and build your business as effectively and quickly as possible but also to become long term partners with all our clients, as they continue to increase their success as a result of working with us."
        />

        {/* <Features
        subheading={<Subheading>Our Values</Subheading>}
        heading="We follow these."
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        cards={[
          {
            imageSrc: SupportIconImage,
            title: "24/7 Support",
            description:
              "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport",
          },
          {
            imageSrc: ShieldIconImage,
            title: "Strong Teams",
            description:
              "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport",
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: "Customer Satisfaction",
            description:
              "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport",
          },
        ]}
        linkText=""
      /> */}
        <TeamCardGrid subheading={<Subheading>Our Team</Subheading>} />
        <Footer />
      </AnimationRevealPage>
    </FeatureContainer>
  );
}

export default About;
