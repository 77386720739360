/* eslint-disable import/no-anonymous-default-export */

import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import Header, {
  NavLink,
  NavLinks,
  PrimaryLink as PrimaryLinkBase,
  LogoLink,
  NavToggle,
  DesktopNavLinks,
} from "../headers/light.js";

const StyledHeader = styled(Header)`
  ${tw`w-full max-w-none pt-8`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:(border-gray-300 text-gray-300)`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;

const PrimaryLink = tw(PrimaryLinkBase)`rounded-full`;
const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 h-screen min-h-144 bg-center bg-cover`}
  background-image: url("https://images.unsplash.com/photo-1536300007881-7e482242baa5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=80");
`;

const OpacityOverlay = tw.div`absolute inset-0 z-10 bg-black opacity-75`;

const HeroContainer = tw.div`relative z-20 mx-auto flex h-full flex-col px-6 sm:px-8`;
const Content = tw.div`flex flex-1 flex-col items-center justify-center px-4`;

const Heading = styled.h1`
  ${tw`-mt-24 text-center font-black text-3xl text-gray-100 leading-snug sm:(mt-0 text-4xl) lg:text-5xl xl:text-6xl`}
  span {
    ${tw`mt-2 inline-block`}
  }
`;

const PrimaryAction = tw.button`mt-10 rounded-full bg-gray-100 bg-primary-500 px-8 py-3 font-bold text-sm text-gray-100 shadow transition duration-300 hocus:(bg-primary-700 text-gray-200) focus:(shadow-outline outline-none) sm:(mt-16 px-8 py-4 text-base)`;

export default () => {
  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="#">About</NavLink>
      <NavLink href="#">Blog</NavLink>
      <NavLink href="#">Locations</NavLink>
      <NavLink href="#">Pricing</NavLink>
    </NavLinks>,
    <NavLinks key={2}>
      <PrimaryLink href="/#">Hire Us</PrimaryLink>
    </NavLinks>,
  ];

  return (
    <Container>
      <OpacityOverlay />
      <HeroContainer>
        <StyledHeader links={navLinks} />
        <Content>
          <Heading>
            Book Music & Comedy Events
            <br />
            anywhere in New York
          </Heading>
          <PrimaryAction>Search Events Near Me</PrimaryAction>
        </Content>
      </HeroContainer>
    </Container>
  );
};
