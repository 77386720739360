import React from "react";
import "style.css";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import Hero4 from "components/hero/TwoColumnWithInput";
import Features from "components/features/DashedBorderSixFeatures";
// import Testimonials from "components/testimonials/TwoColumnWithImageAndProfilePictureReview";
import Footer from "components/footers/MiniCenteredFooter";
// import CTA from "components/cta/GetStartedLight";
import tw from "twin.macro";
import MainFeature1 from "components/features/TwoColWithButton.js";
import Header from "components/headers/light.js";
import Hero from "components/hero/BackgroundAsImage";
import { css } from "styled-components/macro"; //eslint-disable-line
const PlansContainer = tw.div`relative bg-gray-primary px-8`;
const Subheading = tw.span`text-sm uppercase tracking-wider`;
const FeatureContainer = tw.div`bg-gray-primary`;
function MainPage() {
  return (
    <FeatureContainer>
      <Header />
      <AnimationRevealPage>
        <Hero heading="Meet" />

        <PlansContainer>
          <Hero4 />
          <MainFeature1
            subheading={<Subheading>Our Vision</Subheading>}
            heading="Maximizing Your Potential."
            buttonRounded={false}
            primaryButtonUrl="/about"
            imageBorder={true}
            primaryButtonText="Learn More"
            imageSrc="https://images.unsplash.com/photo-1572021335469-31706a17aaef?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
            description="Our model is built for the creative and clever and specifically, to help startups without a technical co-founder just get going, and to know that you have access to our product offerings, which are exclusively offered to our clients, and are made to help you with specific things you will face along the way, like being able to select rotating services each month depending on what you need at the moment (consulting, marketing, design, whatever you might need in the moment, we are uniquely suited to be able to offer that support to all of our past and present clients as a result of our business model. It is not necessary to have a technical cofounder on the team in order to succeed, but things like expressing your ideas with a simple website or reaching a specific audience, can turn into a very time consuming and expensive process unless you already posses the skills."
          />
          <MainFeature1
            subheading={<Subheading>Our Vision</Subheading>}
            heading="Navigating the Business World"
            buttonRounded={false}
            imageBorder={true}
            primaryButtonText="Contact Us"
            primaryButtonUrl="/contact"
            imageSrc="https://cdn.pixabay.com/photo/2018/03/10/12/00/teamwork-3213924_960_720.jpg"
            textOnLeft={false}
            imageVertical={true}
            description="We offer a myriad of consultancy type services that are available to be purchased in packages but also we’ve engineered our offerings to start at your pace and price wherever you are at this very moment in the life of your journey. Our offerings are mostly geared toward giving that special custom edge to early stage startups because that’s where we see the most need and have most of our experience. We’re passionate about lowering the threshold on the barrier to entry to business ownership in general and want to do all we can to help others with aspirations to enter the world of entrepreneurship. We take a deeply consultative approach with every client and we’re constantly looking at how things might be improved overall in every area of your business offering fresh, creative solutions. As we we work with you, we help you understand how to be more effective in your role at your company by helping you understand and track the engagement on your new site, and how to be ready to react effectively to the movements of your customer/client base."
          />
          <Features />
        </PlansContainer>
        {/* <CTA /> */}
        {/* <Testimonials /> */}
        <Footer />
      </AnimationRevealPage>
    </FeatureContainer>
  );
}

export default MainPage;
