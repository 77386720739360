/* eslint-disable import/no-anonymous-default-export */

import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import logo from "../../images/logo-no-background-white.png";

import EmailIllustrationSrc from "../../images/hero-image-home.jpg";

const Container = styled.div((props) => [
  tw`relative -mx-8 -mt-8 bg-center from-gray-400 via-gray-600 to-blue-800 bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))]`,
]);

const OpacityOverlay = tw.div`absolute inset-0 z-10 bg-primary-500 opacity-25`;

const HeroContainer = tw.div`relative z-20 mx-auto max-w-screen-xl px-4 sm:px-8`;
const TwoColumn = tw.div`flex flex-col items-center justify-center px-4 pt-24 pb-32 text-center lg:flex-row`;
const LeftColumn = tw.div`flex flex-col items-center justify-center text-center`;

const Heading = styled.h1`
  ${tw`text-center font-black text-3xl text-gray-100 leading-none sm:text-4xl lg:text-5xl xl:text-6xl`}
  span {
    ${tw`mt-2 inline-block`}
  }
`;

const SlantedBackground = styled.div`
  ${tw`relative -mx-4 rounded-full px-4 py-2 text-primary-500`}
  &::before {
    content: "";
    ${tw`absolute inset-0 -z-10 -skew-x-12 transform bg-gray-800`}
  }
`;
export default ({ heading = "Contact" }) => {
  return (
    <Container imageSrc={EmailIllustrationSrc}>
      <OpacityOverlay />
      <HeroContainer>
        <TwoColumn>
          <LeftColumn>
            <Heading>
              <span>{heading}</span>
              <br />
              <SlantedBackground>
                <img src={logo} alt="logo" />
              </SlantedBackground>
            </Heading>
          </LeftColumn>
        </TwoColumn>
      </HeroContainer>
    </Container>
  );
};
