/* eslint-disable import/no-anonymous-default-export */

import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import {
  Container as ContainerBase,
  ContentWithPaddingXl as ContentBase,
} from "components/misc/Layouts.js";
import { ReactComponent as CheckboxIcon } from "images/checkbox-circle.svg";

const Container = tw(ContainerBase)`-mx-8 bg-primary-900 px-8 text-gray-100`;
const ContentWithPaddingXl = tw(
  ContentBase
)`relative z-10 mx-auto flex max-w-screen-xl flex-col px-0 py-10 sm:(px-6 py-20) md:px-8 lg:px-12 xl:px-24`;
const HeaderContainer = tw.div`mt-10 flex w-full flex-col items-center`;
const Subheading = tw(SubheadingBase)`mb-4 text-gray-100`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center text-gray-300`;

const PlansContainer = tw.div`mt-16 flex flex-col items-center font-medium text-gray-900 lg:(flex-row items-stretch justify-between)`;
const Plan = styled.div`
  ${tw`mx-3 mt-16 flex w-full max-w-sm flex-col justify-between rounded-lg bg-white py-10 px-6 shadow-sm shadow-raised first:mt-0 sm:px-10 lg:(mt-0 px-6 py-10) xl:p-10`}
`;

const PlanHeader = styled.div`
  .nameAndFeaturedContainer {
    ${tw`flex flex-col flex-wrap items-center justify-between sm:flex-row`}
  }
  .name {
    ${tw`mr-3 font-bold uppercase tracking-wider lg:text-lg xl:text-xl`}
  }
  .featuredText {
    ${tw`mt-4 w-full rounded bg-green-300 px-3 py-2 text-center font-bold text-xs text-green-900 uppercase leading-none sm:(mt-0 w-auto)`}
  }
  .pricingContainer {
    ${tw`mt-6 flex items-end justify-between`}
    .currentPrice {
      ${tw`font-bold text-lg leading-none`}
      .bigText {
        ${tw`font-bold text-3xl`}
      }
    }
    .oldPrice {
      ${tw`hidden text-gray-500 text-lg line-through sm:block`}
    }
  }
  .description {
    ${tw`mt-8 font-medium text-gray-700 lg:text-sm xl:text-base`}
  }
`;
const PlanFeatures = styled.ul`
  ${tw`-mx-6 mt-10 flex-1 border-t py-10 px-6 sm:(-mx-10 px-10) lg:(-mx-6 p-6) xl:(-mx-10 p-10)`}
  .feature {
    ${tw`mt-6 flex items-start first:mt-0`}
    .icon {
      ${tw`h-6 w-6 flex-shrink-0 text-primary-500`}
    }
    .text {
      ${tw`ml-3 font-semibold text-primary-900 tracking-wide`}
    }
  }
`;

const PlanAction = tw.div`mt-4`;
const ActionButton = styled(PrimaryButtonBase)`
  ${tw`block w-full rounded bg-primary-500 px-6 py-4 text-center font-semibold text-sm text-gray-100 tracking-wider transition-colors duration-300 hover:bg-primary-700 focus:(shadow-outline outline-none)`}
`;

const WhiteBackgroundOverlay = tw.div`absolute inset-x-0 bottom-0 z-0 h-1/6 bg-white lg:h-1/3`;

export default ({
  subheading = "",
  heading = "Affordable Pricing",
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  plans = null,
  primaryButtonText = "Create Server",
}) => {
  const defaultPlans = [
    {
      name: "Personal",
      price: ["$9", ".99/month"],
      oldPrice: "$11.99",
      description:
        "Perfect for when you want to host your personal blog or a hobby side project.",
      features: [
        "2 Core Xeon CPU",
        "1 GB RAM",
        "30 GB SSD",
        "1 TB Transfer",
        "99.9% Uptime",
      ],
      url: "https://google.com",
    },
    {
      name: "Business",
      price: ["$15", ".99/month"],
      oldPrice: "$19.99",
      description:
        "Perfect for hosting blogs with lots of traffic or heavy development projects",
      features: [
        "4 Core Xeon CPU",
        "2 GB RAM",
        "100 GB SSD",
        "3 TB Transfer",
        "99.9% Uptime",
        "Free Domain & SSL",
        "Free DNS Management",
      ],
      url: "https://google.com",
      featured: "Most Popular",
    },
    {
      name: "Enterprise",
      price: ["$25", ".99/month"],
      oldPrice: "$29.99",
      description:
        "Perfect for hosting production websites & API services with high traffic load",
      features: [
        "8 Core Xeon CPU",
        "8 GB RAM",
        "300 GB SSD",
        "Unlimited Transfer",
        "99.99% Uptime",
        "Free Domain & SSL",
        "Free DNS Management",
        "Free Offsite Backup",
      ],
      url: "https://google.com",
    },
  ];

  if (!plans) plans = defaultPlans;

  return (
    <Container>
      <ContentWithPaddingXl>
        <HeaderContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          {description && <Description>{description}</Description>}
        </HeaderContainer>
        <PlansContainer>
          {plans.map((plan, index) => (
            <Plan key={index} featured={plan.featured}>
              <PlanHeader>
                <span className="nameAndFeaturedContainer">
                  <span className="name">{plan.name}</span>
                  {plan.featured && (
                    <span className="featuredText">{plan.featured}</span>
                  )}
                </span>
                <div className="pricingContainer">
                  <span className="currentPrice">
                    <span className="bigText">{plan.price[0]}</span>
                    {plan.price[1]}{" "}
                  </span>
                  {plan.oldPrice && (
                    <span className="oldPrice">{plan.oldPrice}</span>
                  )}
                </div>
                <p className="description">{plan.description}</p>
              </PlanHeader>
              <PlanFeatures>
                {plan.features.map((feature, index) => (
                  <li className="feature" key={index}>
                    <CheckboxIcon className="icon" />
                    <span className="text">{feature}</span>
                  </li>
                ))}
              </PlanFeatures>
              <PlanAction>
                <ActionButton as="a" href={plan.url}>
                  {primaryButtonText}
                </ActionButton>
              </PlanAction>
            </Plan>
          ))}
        </PlansContainer>
      </ContentWithPaddingXl>
      <WhiteBackgroundOverlay />
    </Container>
  );
};
