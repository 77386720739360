/* eslint-disable import/no-anonymous-default-export */

import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";

const Container = tw.div`relative`;
const Content = tw.div`mx-auto max-w-screen-xl py-20 lg:py-24`;

const FormContainer = styled.div`
  ${tw`relative rounded-lg bg-primary-500 p-10 text-gray-100 sm:p-12 md:p-16`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`font-bold text-3xl sm:text-4xl`}
  }
  input,
  textarea {
    ${tw`w-full border-b-2 bg-transparent py-2 font-medium text-gray-100 text-base text-gray-100 tracking-wide transition duration-200 hocus:border-pink-400 focus:outline-none`};

    ::placeholder {
      ${tw`text-gray-500`}
    }
  }
`;

const TwoColumn = tw.div`flex flex-col justify-between sm:flex-row`;
const Column = tw.div`flex flex-col sm:w-5/12`;
const InputContainer = tw.div`relative mt-6 py-5`;
const Label = tw.label`absolute top-0 left-0 font-semibold text-sm tracking-wide`;
const Input = tw.input``;
const TextArea = tw.textarea`h-24 resize-none sm:h-full`;
const SubmitButton = tw.button`mt-6 w-full transform rounded-full bg-gray-100 py-3 font-bold text-primary-500 text-sm uppercase tracking-wide shadow-lg transition duration-300 hocus:(-translate-y-px shadow-xl) hover:(bg-gray-300 text-primary-700) focus:(shadow-outline outline-none) sm:w-32`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 -z-10 w-24 translate-y-1/2 translate-x-1/2 transform fill-current text-primary-500 opacity-50`;

export default () => {
  return (
    <Container>
      <Content>
        <FormContainer>
          <div tw="mx-auto max-w-4xl">
            <h2>Organize an Event</h2>
            <form action="#">
              <TwoColumn>
                <Column>
                  <InputContainer>
                    <Label htmlFor="name-input">Your Name</Label>
                    <Input
                      id="name-input"
                      type="text"
                      name="name"
                      placeholder="E.g. John Doe"
                    />
                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="email-input">Your Email Address</Label>
                    <Input
                      id="email-input"
                      type="email"
                      name="email"
                      placeholder="E.g. john@mail.com"
                    />
                  </InputContainer>
                </Column>
                <Column>
                  <InputContainer tw="flex-1">
                    <Label htmlFor="name-input">Your Message</Label>
                    <TextArea
                      id="message-input"
                      name="message"
                      placeholder="E.g. Details about your event"
                    />
                  </InputContainer>
                </Column>
              </TwoColumn>

              <SubmitButton type="submit" value="Submit">
                Submit
              </SubmitButton>
            </form>
          </div>
          <SvgDotPattern1 />
        </FormContainer>
      </Content>
    </Container>
  );
};
