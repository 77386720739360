/* eslint-disable import/no-anonymous-default-export */

import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings";
import { SectionDescription } from "components/misc/Typography";
// import { ReactComponent as TwitterIcon } from "images/twitter-icon.svg";
// import { ReactComponent as LinkedinIcon } from "images/linkedin-icon.svg";
// import { ReactComponent as GithubIcon } from "images/github-icon.svg";

const HeadingContainer = tw.div``;
const Heading = tw(SectionHeading)``;
const Subheading = tw(SubheadingBase)`mb-3 text-center`;
const Description = tw(
  SectionDescription
)`mx-auto text-center font-bold text-[#383C42]`;

const Cards = tw.div`mx-auto flex flex-row flex-wrap justify-center sm:max-w-2xl lg:max-w-5xl`;
const Card = tw.div`mt-24 flex w-full flex-col items-center sm:w-1/2 lg:w-1/3`;
const CardImage = styled.div`
  ${(props) =>
    css`
      background-image: url("${props.imageSrc}");
    `}
  ${tw`h-64 w-64 rounded bg-contain bg-center`}
`;
const CardContent = styled.div`
  ${tw`mt-6 flex flex-col items-center`}
  .position {
    ${tw`font-bold text-xs text-primary-500 uppercase tracking-widest`}
  }
  .name {
    ${tw`mt-1 font-bold text-xl text-[#383C42]`}
  9
`;

const CardLinks = styled.div`
  ${tw`mt-6 flex`}
  .link {
    ${tw`mr-8 text-gray-400 transition duration-300 hocus:text-primary-500 last:mr-0`}
    .icon {
      ${tw`h-6 w-6 fill-current`}
    }
  }
`;

export default ({
  heading = "Meet the Founders",
  subheading = "Our Team",
  description = "Our team is dedicated to providing you with the best possible service and helping you achieve your goals. We understand the importance of building strong relationships and truly understanding your needs.",
  cards = [
    {
      imageSrc:
        "https://images.unsplash.com/photo-1614023342667-6f060e9d1e04?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8TUFOJTIwU01JTElOR3xlbnwwfHwwfHw%3D&auto=format&fit=crop&fit=facearea&facepad=2.95&w=512&h=512&q=80",
      position: "Founder",
      name: "Desmond F",
      links: [
        // {
        //   url: "https://twitter.com",
        //   icon: TwitterIcon,
        // },
        // {
        //   url: "https://linkedin.com",
        //   icon: LinkedinIcon,
        // },
        // {
        //   url: "https://github.com",
        //   icon: GithubIcon,
        // },
      ],
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&fit=facearea&facepad=2.95&w=512&h=512&q=80",
      position: "Founder",
      name: "Zak Z",
      links: [
        // {
        //   url: "https://twitter.com",
        //   icon: TwitterIcon,
        // },
        // {
        //   url: "https://linkedin.com",
        //   icon: LinkedinIcon,
        // },
        // {
        //   url: "https://github.com",
        //   icon: GithubIcon,
        // },
      ],
    },
  ],
}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          {heading && <Heading>{heading}</Heading>}
          {description && <Description>{description}</Description>}
        </HeadingContainer>
        <Cards>
          {cards.map((card, index) => (
            <Card key={index}>
              <CardImage imageSrc={card.imageSrc} />
              <CardContent>
                <span className="position">{card.position}</span>
                <span className="name">{card.name}</span>
                <CardLinks>
                  {card.links.map((link, linkIndex) => (
                    <a key={linkIndex} className="link" href={link.url}>
                      <link.icon className="icon" />
                    </a>
                  ))}
                </CardLinks>
              </CardContent>
            </Card>
          ))}
        </Cards>
      </ContentWithPaddingXl>
    </Container>
  );
};
