/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import mockupImageSrc from "images/app-mockup.png";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-9.svg";
import {
  ContentWithPaddingXl,
  Container as ContainerBase,
} from "components/misc/Layouts";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import appleIconImageSrc from "images/apple-icon.png";
import googlePlayIconImageSrc from "images/google-play-icon.png";

const Container = tw(ContainerBase)`-mx-8 bg-gray-900`;
const Content = tw(ContentWithPaddingXl)``;
const Row = tw.div`relative z-10 flex flex-col items-center justify-center px-8 text-center lg:(flex-row text-left)`;

const ColumnContainer = tw.div`max-w-2xl`;
const TextContainer = tw(ColumnContainer)``;
const Text = tw(
  SectionHeading
)`max-w-none text-gray-100 text-3xl leading-snug lg:text-left`;
const Subheading = tw(SubheadingBase)`mb-4 text-yellow-500 tracking-wider`;

const LinksContainer = tw.div`mt-8 flex flex-col items-center sm:block lg:mt-16`;
const Link = styled.a`
  ${tw`mt-6 inline-flex w-56 items-center justify-center rounded-full bg-gray-100 p-3 font-bold text-sm text-gray-900 uppercase tracking-wider shadow transition duration-300 hocus:(bg-gray-300 text-gray-900) first:(mt-0 ml-0) hover:shadow-lg focus:(shadow-outline outline-none) sm:(mt-0 ml-8 p-4 text-base)`}
  img {
    ${tw`mr-3 inline-block h-8`}
  }
  span {
    ${tw`inline-block leading-none`}
  }
`;

const ImageContainer = tw(
  ColumnContainer
)`mt-16 flex justify-end lg:(mt-0 ml-16)`;

const DecoratorBlobContainer = tw.div`absolute inset-0 overflow-hidden rounded-lg`;
const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`absolute bottom-0 left-0 h-80 w-80 -translate-x-20 translate-y-32 transform text-gray-800 opacity-50`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob1
)`absolute top-0 right-0 h-80 w-80 translate-x-20 -translate-y-64 transform text-gray-800 opacity-50`;
export default ({
  subheading = "Download App",
  text = "Developers all over the world are happily using Treact.",
  link1Text = "App Store",
  link1Url = "http://apple.com",
  link1IconSrc = appleIconImageSrc,
  link2Text = "Google Play",
  link2Url = "http://play.google.com",
  link2IconSrc = googlePlayIconImageSrc,
  pushDownFooter = false,
  imageSrc = mockupImageSrc,
}) => {
  return (
    <Container css={pushDownFooter && tw`mb-20 lg:mb-24`}>
      <Content>
        <Row>
          <TextContainer>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Text>{text}</Text>
            <LinksContainer>
              <Link href={link1Url}>
                <img src={link1IconSrc} alt="" />
                <span>{link1Text}</span>
              </Link>
              <Link href={link2Url}>
                <img src={link2IconSrc} alt="" />
                <span>{link2Text}</span>
              </Link>
            </LinksContainer>
          </TextContainer>
          <ImageContainer>
            <img src={imageSrc} alt="" tw="w-64" />
          </ImageContainer>
        </Row>
        <DecoratorBlobContainer>
          <DecoratorBlob1 />
          <DecoratorBlob2 />
        </DecoratorBlobContainer>
      </Content>
    </Container>
  );
};
