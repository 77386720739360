/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import TeamIllustrationSrc from "images/team-illustration-2.svg";
import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";

const Container = tw.div`relative mx-auto`;
const TwoColumn = tw.div`mx-auto flex flex-col items-center justify-between py-20 md:(flex-row py-24)`;
const Column = tw.div`mx-auto w-full max-w-md md:(mx-0 max-w-none)`;
const ImageColumn = tw(Column)`relative flex-shrink-0 md:w-6/12`;
const TextColumn = styled(Column)((props) => [
  tw`mx-auto mt-16 md:(mt-0 w-6/12)`,
  props.textOnLeft
    ? tw`md:(order-first mr-12) lg:mr-16`
    : tw`md:(order-last ml-12) lg:ml-16`,
]);

const Image = styled.img((props) => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`,
  props.imageVertical && tw`w-auto h-[60vh]`,
  tw`mx-auto h-full object-cover md:(h-full w-full)`,
]);

const DecoratorBlob = styled(SvgDotPattern)((props) => [
  tw`absolute right-0 bottom-0 -z-10 h-20 w-20 translate-x-1/2 translate-y-1/2 transform fill-current text-primary-500`,
]);

const TextContent = tw.div`text-center lg:py-8`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 text-left text-center font-black text-3xl leading-tight sm:text-4xl md:text-left lg:text-5xl`;
const Description = tw.p`mt-4 text-center font-bold text-sm leading-relaxed text-[#383C42] md:(text-left text-base) lg:text-lg`;

const PrimaryButton = styled(PrimaryButtonBase)((props) => [
  tw`mx-auto mt-8 inline-block w-full rounded-full text-center text-lg md:(mx-0 mt-8)`,
  props.buttonRounded && tw`rounded-full`,
]);

export default ({
  subheading = "Our Expertise",
  heading = (
    <>
      Designed & Developed by <span tw="text-primary-500">Professionals.</span>
    </>
  ),
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  primaryButtonText = "",
  primaryButtonUrl = "",
  imageSrc = TeamIllustrationSrc,
  buttonRounded = true,
  imageRounded = true,
  imageBorder = false,
  imageShadow = false,
  imageCss = null,
  imageVertical = null,
  imageDecoratorBlob = false,
  imageDecoratorBlobCss = null,
  textOnLeft = true,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image
            css={imageCss}
            src={imageSrc}
            imageBorder={imageBorder}
            imageShadow={imageShadow}
            imageRounded={imageRounded}
            imageVertical={imageVertical}
          />
          {imageDecoratorBlob && <DecoratorBlob css={imageDecoratorBlobCss} />}
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Subheading>{subheading}</Subheading>
            <Heading>{heading}</Heading>
            <Description>{description}</Description>
            {primaryButtonText && primaryButtonUrl && (
              <PrimaryButton
                buttonRounded={buttonRounded}
                as="a"
                href={primaryButtonUrl}
              >
                {primaryButtonText}
              </PrimaryButton>
            )}
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
