import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import Header from "components/headers/light";
import ContactForm from "components/forms/TwoColContactUsWithIllustrationFullForm";
import Footer from "components/footers/MiniCenteredFooter";
import "style.css";
import tw from "twin.macro";
import Hero from "components/hero/BackgroundAsImage.js";
function Pricing() {
  const FeatureContainer = tw.div`bg-gray-primary`;

  return (
    <FeatureContainer>
      <Header />
      <AnimationRevealPage>
        <Hero heading="Contact" />

        <ContactForm />
        <Footer />
      </AnimationRevealPage>
    </FeatureContainer>
  );
}

export default Pricing;
