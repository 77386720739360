/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import StatsIllustrationSrc from "images/stats-illustration.svg";
import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";

const Container = tw.div`relative`;
const TwoColumn = tw.div`mx-auto flex max-w-screen-xl flex-col justify-between py-20 md:(flex-row py-24)`;
const Column = tw.div`mx-auto w-full max-w-md md:(mx-0 max-w-none)`;
const ImageColumn = tw(Column)`relative h-80 flex-shrink-0 md:(h-auto w-5/12)`;
const TextColumn = styled(Column)((props) => [
  tw`mt-16 md:(mt-0 w-7/12)`,
  props.textOnLeft
    ? tw`md:(order-first mr-12) lg:mr-16`
    : tw`md:(order-last ml-12) lg:ml-16`,
]);

const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`h-full rounded bg-contain bg-center bg-no-repeat`,
]);
const TextContent = tw.div`text-center md:text-left lg:py-8`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 text-left text-center font-black text-3xl leading-tight sm:text-4xl md:text-left lg:text-5xl`;
const Description = tw.p`mt-4 text-center font-medium text-sm text-secondary-100 leading-relaxed md:(text-left text-base) lg:text-lg`;

const Statistics = tw.div`mt-4 flex flex-col items-center text-center sm:block md:text-left`;
const Statistic = tw.div`mt-4 text-left last:mr-0 sm:(mr-12 inline-block)`;
const Value = tw.div`font-bold text-lg text-secondary-500 tracking-wide sm:text-xl lg:text-2xl`;
const Key = tw.div`font-medium text-primary-700`;

const PrimaryButton = tw(
  PrimaryButtonBase
)`mx-auto mt-8 inline-block text-sm md:(mx-0 mt-10)`;

const DecoratorBlob = styled(SvgDotPattern)((props) => [
  tw`absolute right-0 bottom-0 -z-10 h-20 w-20 translate-x-1/2 translate-y-1/2 transform fill-current text-primary-500`,
]);

export default ({
  subheading = "Our Track Record",
  heading = (
    <>
      We have been doing this <wbr /> since{" "}
      <span tw="text-primary-500">1999.</span>
    </>
  ),
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  primaryButtonText = "Learn More",
  primaryButtonUrl = "https://timerse.com",
  imageSrc = StatsIllustrationSrc,
  imageCss = null,
  imageContainerCss = null,
  imageDecoratorBlob = false,
  imageDecoratorBlobCss = null,
  imageInsideDiv = true,
  statistics = null,
  textOnLeft = false,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  //Change the statistics variable as you like, add or delete objects
  const defaultStatistics = [
    {
      key: "Clients",
      value: "2282+",
    },
    {
      key: "Projects",
      value: "3891+",
    },
    {
      key: "Awards",
      value: "1000+",
    },
  ];

  if (!statistics) statistics = defaultStatistics;

  return (
    <Container>
      <TwoColumn css={!imageInsideDiv && tw`md:items-center`}>
        <ImageColumn css={imageContainerCss}>
          {imageInsideDiv ? (
            <Image imageSrc={imageSrc} css={imageCss} />
          ) : (
            <img src={imageSrc} css={imageCss} alt="" />
          )}
          {imageDecoratorBlob && <DecoratorBlob css={imageDecoratorBlobCss} />}
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            <Description>{description}</Description>
            <Statistics>
              {statistics.map((statistic, index) => (
                <Statistic key={index}>
                  <Value>{statistic.value}</Value>
                  <Key>{statistic.key}</Key>
                </Statistic>
              ))}
            </Statistics>
            <PrimaryButton as="a" href={primaryButtonUrl}>
              {primaryButtonText}
            </PrimaryButton>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
