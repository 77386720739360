/* eslint-disable import/no-anonymous-default-export */

import React, { useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";

import Header from "../headers/light.js";

import ReactModalAdapter from "../../helpers/ReactModalAdapter.js";
import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed.js";

import { ReactComponent as PlayIcon } from "feather-icons/dist/icons/play-circle.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/dot-pattern.svg";
import DesignIllustration from "../../images/design-illustration.svg";

const Container = tw.div`relative`;
const TwoColumn = tw.div`mx-auto flex max-w-screen-xl flex-col py-20 md:(items-center py-24) lg:flex-row`;
const LeftColumn = tw.div`relative flex-shrink-0 text-center lg:(w-6/12 pr-12 text-left)`;
const RightColumn = tw.div`relative mt-12 flex flex-col justify-center lg:mt-0`;

const Heading = tw.h1`max-w-3xl font-black text-3xl leading-snug md:text-5xl`;
const Paragraph = tw.p`my-5 mx-auto max-w-lg font-medium text-sm text-gray-600 lg:(my-8 mx-0 text-base)`;

const Actions = tw.div`mt-8 flex flex-col items-center justify-center sm:flex-row lg:justify-start`;
const PrimaryButton = tw.button`rounded bg-primary-500 px-8 py-3 font-bold text-gray-100 transition duration-300 hocus:bg-primary-700 focus:(shadow-outline outline-none) lg:px-10`;
const WatchVideoButton = styled.button`
  ${tw`mt-4 flex items-center text-secondary-300 transition duration-300 hocus:text-primary-400 focus:outline-none sm:(mt-0 ml-8)`}
  .playIcon {
    ${tw`h-12 w-12 stroke-1`}
  }
  .playText {
    ${tw`ml-2 font-medium`}
  }
`;

const IllustrationContainer = tw.div`relative flex max-w-3xl items-center justify-center md:justify-end lg:max-w-none`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none absolute left-0 bottom-0 -z-10 h-64 w-64 -translate-x-2/3 transform opacity-5`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none absolute right-0 bottom-0 -z-10 h-32 w-32 translate-x-10 translate-y-10 transform fill-current text-primary-500 opacity-25`}
`;

const StyledModal = styled(ReactModalAdapter)`
  &.mainHeroModal__overlay {
    ${tw`fixed inset-0 z-50`}
  }
  &.mainHeroModal__content {
    ${tw`absolute inset-0 m-4 flex max-w-screen-xl items-center justify-center rounded-lg bg-gray-200 outline-none sm:m-16 xl:mx-auto`}
  }
  .content {
    ${tw`w-full lg:p-16`}
  }
`;
const CloseModalButton = tw.button`absolute top-0 right-0 mt-8 mr-8 hocus:text-primary-500`;

export default ({
  heading = "Modern React Templates, Just For You",
  description = "Our templates are easy to setup, understand and customize. Fully modular components with a variety of pages and components.",
  primaryButtonText = "Get Started",
  primaryButtonUrl = "#",
  watchVideoButtonText = "Watch Video",
  watchVideoYoutubeUrl = "https://www.youtube.com/embed/_GuOjXYl5ew",
  imageSrc = DesignIllustration,
  imageCss = null,
  imageDecoratorBlob = false,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const toggleModal = () => setModalIsOpen(!modalIsOpen);

  return (
    <>
      <Header />
      <Container>
        <TwoColumn>
          <LeftColumn>
            <Heading>{heading}</Heading>
            <Paragraph>{description}</Paragraph>
            <Actions>
              <PrimaryButton as="a" href={primaryButtonUrl}>
                {primaryButtonText}
              </PrimaryButton>
              <WatchVideoButton onClick={toggleModal}>
                <span className="playIconContainer">
                  <PlayIcon className="playIcon" />
                </span>
                <span className="playText">{watchVideoButtonText}</span>
              </WatchVideoButton>
            </Actions>
          </LeftColumn>
          <RightColumn>
            <IllustrationContainer>
              <img css={imageCss} src={imageSrc} alt="Hero" />
              {imageDecoratorBlob && <DecoratorBlob2 />}
            </IllustrationContainer>
          </RightColumn>
        </TwoColumn>
        <DecoratorBlob1 />
        <StyledModal
          closeTimeoutMS={300}
          className="mainHeroModal"
          isOpen={modalIsOpen}
          onRequestClose={toggleModal}
          shouldCloseOnOverlayClick={true}
        >
          <CloseModalButton onClick={toggleModal}>
            <CloseIcon tw="h-6 w-6" />
          </CloseModalButton>
          <div className="content">
            <ResponsiveVideoEmbed url={watchVideoYoutubeUrl} tw="w-full" />
          </div>
        </StyledModal>
      </Container>
    </>
  );
};
