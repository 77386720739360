import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import Header from "components/headers/light";
import PricingPlans from "components/pricing/ThreePlans";
import Footer from "components/footers/MiniCenteredFooter";
import "style.css";
import tw from "twin.macro";
import Hero from "components/hero/BackgroundAsImage.js";
// import Faqs from "components/faqs/SimpleWithSideImage";
function Pricing() {
  const FeatureContainer = tw.div`bg-gray-primary`;

  return (
    <FeatureContainer>
      <Header />
      <AnimationRevealPage>
        <Hero heading="Pricing For" />
        <PricingPlans />
        {/* <Faqs /> */}
        <Footer />
      </AnimationRevealPage>
    </FeatureContainer>
  );
}

export default Pricing;
