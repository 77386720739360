/* eslint-disable import/no-anonymous-default-export */

import React from "react";
import tw from "twin.macro";
import { ReactComponent as EmailNewsletterIconBase } from "../../images/email-newsletter-icon.svg";
import { Container as ContainerBase } from "components/misc/Layouts.js";
import { SectionHeading } from "components/misc/Headings.js";
import { PrimaryButton } from "components/misc/Buttons.js";

const Container = tw(ContainerBase)`-mx-8 bg-secondary-800`;
const Content = tw.div`mx-auto max-w-screen-xl py-20 lg:py-24`;

const Row = tw.div`flex flex-col items-center justify-center px-8 lg:flex-row`;
const TextColumn = tw.div`flex flex-col items-center sm:flex-row`;
const FormColumn = tw.div`mt-12 w-full sm:w-auto lg:(mt-0 ml-16)`;

const EmailNewsletterIcon = tw(
  EmailNewsletterIconBase
)`h-16 w-16 text-green-500`;
const HeadingInfoContainer = tw.div`mt-6 sm:(ml-6 mt-0)`;
const Heading = tw(SectionHeading)`text-gray-100 leading-none sm:text-left`;
const Description = tw.p`mt-2 max-w-sm text-center font-medium text-gray-500 text-sm sm:(mt-1 text-left)`;

const Form = tw.form`mx-auto max-w-sm text-sm sm:max-w-none`;
const Input = tw.input`block w-full rounded border border-secondary-600 bg-secondary-600 px-6 py-4 font-bold text-gray-200 tracking-wider transition duration-300 hover:bg-secondary-500 focus:(border-secondary-300 outline-none) sm:(inline-block w-auto rounded-r-none)`;
const Button = tw(
  PrimaryButton
)`mt-6 w-full border border-green-500 bg-green-500 py-4 text-gray-100 hocus:(border-green-700 bg-green-700 text-gray-300) sm:(mt-0 w-auto rounded-l-none)`;

export default () => {
  return (
    <Container>
      <Content>
        <Row>
          <TextColumn>
            <EmailNewsletterIcon />
            <HeadingInfoContainer>
              <Heading>Newsletter</Heading>
              <Description>
                Subscribe now to get our latest blog posts.
              </Description>
            </HeadingInfoContainer>
          </TextColumn>
          <FormColumn>
            <Form>
              <Input
                name="newsletter"
                type="email"
                placeholder="Your Email Address"
              />
              <Button type="submit">Subscribe Now</Button>
            </Form>
          </FormColumn>
        </Row>
      </Content>
    </Container>
  );
};
