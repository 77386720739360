/* eslint-disable import/no-anonymous-default-export */

import React from "react";
import tw from "twin.macro";
// import styled from "styled-components";
import { Container as ContainerBase } from "components/misc/Layouts.js";
import logo from "../../images/logo-no-background-white.png";
// import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg";
// import { ReactComponent as TwitterIcon } from "../../images/twitter-icon.svg";
// import { ReactComponent as YoutubeIcon } from "../../images/youtube-icon.svg";

const Container = tw(
  ContainerBase
)`-mx-8 -mb-8 h-80 bg-gray-800 text-white md:h-48 lg:h-56`;
const Content = tw.div`mx-auto max-w-screen-xl py-8 md:py-12`;

const Row = tw.div`flex flex-col items-center justify-center px-8`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-32`;
// const LogoText = tw.h5`ml-2 text-2xl font-black tracking-wider`;

const LinksContainer = tw.div`flex flex-col flex-wrap items-center justify-center font-medium sm:flex-row`;
const Link = tw.a`mx-4 mt-2 border-transparent border-b-2 pb-1 transition duration-300 hocus:(border-gray-300 text-gray-300)`;

// const SocialLinksContainer = tw.div`mt-10`;
// const SocialLink = styled.a`
//   ${tw`cursor-pointer inline-block text-gray-100 hover:text-gray-500 transition duration-300 mx-4`}
//   svg {
//     ${tw`w-5 h-5`}
//   }
// `;

const CopyrightText = tw.p`text-center font-medium text-sm text-white tracking-wide`;
export default () => {
  return (
    <Container>
      <Content>
        <Row>
          <LogoContainer>
            <LogoImg src={logo} />
            {/* <LogoText>WeBuilder</LogoText> */}
          </LogoContainer>
          <LinksContainer>
            <Link href="/">Home</Link>
            <Link href="/about">About</Link>
            <Link href="/pricing">Pricing</Link>
            <Link href="/contact">Contact Us</Link>
            <Link href="/terms">Terms of Service</Link>
            {/* <Link href="#">Reviews</Link> */}
          </LinksContainer>
          {/* <SocialLinksContainer>
            <SocialLink href="https://facebook.com">
              <FacebookIcon />
            </SocialLink>
            <SocialLink href="https://twitter.com">
              <TwitterIcon />
            </SocialLink>
            <SocialLink href="https://youtube.com">
              <YoutubeIcon />
            </SocialLink>
          </SocialLinksContainer> */}
          <CopyrightText>
            &copy; Copyright 2023, WeBuilderX Inc. All Rights Reserved.
          </CopyrightText>
        </Row>
      </Content>
    </Container>
  );
};
