/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";

const Container = tw.div`relative`;

const SingleColumn = tw.div`mx-auto max-w-screen-xl py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 max-w-sm text-center font-medium text-gray-600`;

const Content = tw.div`mt-16`;

const Card = styled.div((props) => [
  tw`mt-24 items-center justify-center md:flex`,
  props.reversed ? tw`flex-row-reverse` : "flex-row",
]);
const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`mx-4 h-80 flex-shrink-0 rounded bg-cover bg-center sm:mx-8 md:(mx-4 h-144 w-1/2) lg:(mx-8 w-5/12) xl:w-1/3`,
]);
const Details = tw.div`mx-4 mt-4 sm:mx-8 md:(mx-4 mt-0 max-w-md) lg:mx-8`;
const Subtitle = tw.div`font-bold text-secondary-100 tracking-wide`;
const Title = tw.h4`font-bold text-3xl text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;
const Link = tw.a`mt-4 inline-block cursor-pointer border-transparent border-b-2 font-bold text-sm text-primary-500 transition duration-300 hover:border-primary-500`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 -z-10 w-24 -translate-x-20 translate-y-8 rotate-90 transform fill-current text-primary-500 opacity-25`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 -z-10 w-24 translate-x-20 translate-y-24 rotate-45 transform fill-current text-primary-500 opacity-25`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 -z-10 w-24 -translate-x-20 -translate-y-8 rotate-45 transform fill-current text-primary-500 opacity-25`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 -z-10 w-24 translate-x-20 -translate-y-24 rotate-90 transform fill-current text-primary-500 opacity-25`;

export default () => {
  const cards = [
    {
      imageSrc:
        "https://images.unsplash.com/photo-1550699026-4114bbf4fb49?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=632&q=80",
      subtitle: "Paid",
      title: "Loachella, NYC",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      url: "https://timerse.com",
    },

    {
      imageSrc:
        "https://images.unsplash.com/photo-1543423924-b9f161af87e4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80",
      subtitle: "Free",
      title: "Rock In Rio, Upstate",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      url: "https://timerse.com",
    },

    {
      imageSrc:
        "https://images.unsplash.com/photo-1509824227185-9c5a01ceba0d?ixlib=rb-1.2.1&auto=format&fit=crop&w=658&q=80",
      subtitle: "Exclusive",
      title: "Lollapalooza, Manhattan",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      url: "https://timerse.com",
    },
  ];

  return (
    <Container>
      <SingleColumn>
        <HeadingInfoContainer>
          <HeadingTitle>Popular Events</HeadingTitle>
          <HeadingDescription>
            Here are some of the most popular events in New York City curated by
            professionals.
          </HeadingDescription>
        </HeadingInfoContainer>

        <Content>
          {cards.map((card, i) => (
            <Card key={i} reversed={i % 2 === 1}>
              <Image imageSrc={card.imageSrc} />
              <Details>
                <Subtitle>{card.subtitle}</Subtitle>
                <Title>{card.title}</Title>
                <Description>{card.description}</Description>
                <Link href={card.url}>See Event Details</Link>
              </Details>
            </Card>
          ))}
        </Content>
      </SingleColumn>
      <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 />
    </Container>
  );
};
