/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import TeamIllustrationSrc from "images/team-illustration-2.svg";
import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";

const Container = tw.div`relative`;
const TwoColumn = tw.div`mx-auto flex max-w-screen-xl flex-col items-center justify-between py-20 md:(flex-row py-24)`;
const Column = tw.div`mx-auto w-full max-w-md md:(mx-0 max-w-none)`;
const ImageColumn = tw(Column)`relative flex-shrink-0 md:w-6/12`;
const TextColumn = styled(Column)((props) => [
  tw`mt-16 md:(mt-0 w-6/12)`,
  props.textOnLeft
    ? tw`md:(order-first mr-12) lg:mr-16`
    : tw`md:(order-last ml-12) lg:ml-16`,
]);

const Image = styled.img((props) => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`,
]);

const DecoratorBlob = styled(SvgDotPattern)(() => [
  tw`absolute right-0 bottom-0 -z-10 h-20 w-20 translate-x-1/2 translate-y-1/2 transform fill-current text-primary-500`,
]);

const TextContent = tw.div`text-center md:text-left lg:py-8`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 text-left text-center font-black text-3xl leading-tight sm:text-4xl md:text-left lg:text-5xl`;

const Steps = tw.ul`mt-12`;
const Step = tw.li`mt-8 flex flex-col items-center md:(flex-row items-start)`;
const StepNumber = tw.div`font-semibold text-4xl text-gray-400 leading-none`;
const StepText = tw.div`mt-3 md:(mt-0 ml-6)`;
const StepHeading = tw.h6`font-semibold text-xl leading-none`;
const StepDescription = tw.p`mt-3 max-w-xs font-medium text-sm text-gray-600 leading-loose`;

export default ({
  subheading = "Our Expertise",
  heading = (
    <>
      Designed & Developed by <span tw="text-primary-500">Professionals.</span>
    </>
  ),
  imageSrc = TeamIllustrationSrc,
  imageRounded = true,
  imageBorder = false,
  imageShadow = false,
  imageDecoratorBlob = false,
  textOnLeft = true,
  steps = null,
  decoratorBlobCss = null,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  const defaultSteps = [
    {
      heading: "Register",
      description: "Create an account with us using Google or Facebook.",
    },
    {
      heading: "Download",
      description:
        "Browse and Download the template that you like from the marketplace.",
    },
    {
      heading: "Run",
      description:
        "Follow the instructions to setup and customize the template to your needs.",
    },
  ];

  if (!steps) steps = defaultSteps;

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image
            src={imageSrc}
            imageBorder={imageBorder}
            imageShadow={imageShadow}
            imageRounded={imageRounded}
          />
          {imageDecoratorBlob && <DecoratorBlob css={decoratorBlobCss} />}
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Subheading>{subheading}</Subheading>
            <Heading>{heading}</Heading>
            <Steps>
              {steps.map((step, index) => (
                <Step key={index}>
                  <StepNumber>
                    {(index + 1).toString().padStart(2, "0")}
                  </StepNumber>
                  <StepText>
                    <StepHeading>{step.heading}</StepHeading>
                    <StepDescription>{step.description}</StepDescription>
                  </StepText>
                </Step>
              ))}
            </Steps>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
