/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-9.svg";
import { ContentWithPaddingXl, Container } from "components/misc/Layouts";

const PrimaryBackgroundContainer = tw.div`relative rounded-lg bg-purple-200 py-16 lg:py-20`;
const Row = tw.div`relative z-10 mx-auto flex flex-col items-center justify-center px-4 text-center sm:px-16 lg:(flex-row text-left)`;

const ColumnContainer = tw.div`max-w-lg lg:w-1/2`;
const TextContainer = tw(ColumnContainer)`font-bold text-2xl sm:text-4xl`;
const Subheading = tw.h6`text-primary-500 opacity-75`;
const Heading = tw.h5`text-primary-500`;

const LinksContainer = tw(
  ColumnContainer
)`mt-6 flex flex-col justify-center sm:flex-row lg:(mt-0 justify-end)`;

const Link = tw.a`mt-4 w-full rounded border border-transparent px-6 py-3 font-bold text-sm tracking-wide transition duration-300 first:mt-0 focus:(shadow-outline outline-none) sm:(mt-0 mr-8 w-auto px-8 py-4 text-base last:mr-0) lg:(px-10 py-5)`;
const PrimaryLink = tw(
  Link
)`bg-primary-500 text-gray-100 shadow hocus:(bg-primary-700 text-gray-300)`;

const SecondaryLink = tw(
  Link
)`bg-gray-100 text-primary-500 hover:(bg-gray-200 text-primary-600)`;

const DecoratorBlobContainer = tw.div`absolute inset-0 overflow-hidden rounded-lg`;
const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`absolute bottom-0 left-0 h-80 w-80 -translate-x-20 translate-y-32 transform text-primary-500 opacity-5`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob1
)`absolute top-0 right-0 h-80 w-80 translate-x-20 -translate-y-64 transform text-primary-500 opacity-5`;
export default ({
  subheading = "Interested in Treact ?",
  heading = "Join the closed beta now.",
  primaryLinkText = "Get Started",
  primaryLinkUrl = "http://timerse.com",
  secondaryLinkText = "Contact Us",
  secondaryLinkUrl = "http://google.com",
  pushDownFooter = true,
}) => {
  return (
    <Container css={pushDownFooter && tw`mb-20 lg:mb-24`}>
      <ContentWithPaddingXl>
        <PrimaryBackgroundContainer>
          <Row>
            <TextContainer>
              {subheading && <Subheading>{subheading}</Subheading>}
              <Heading>{heading}</Heading>
            </TextContainer>
            <LinksContainer>
              <PrimaryLink href={primaryLinkUrl}>{primaryLinkText}</PrimaryLink>
              <SecondaryLink href={secondaryLinkUrl}>
                {secondaryLinkText}
              </SecondaryLink>
            </LinksContainer>
          </Row>
          <DecoratorBlobContainer>
            <DecoratorBlob1 />
            <DecoratorBlob2 />
          </DecoratorBlobContainer>
        </PrimaryBackgroundContainer>
      </ContentWithPaddingXl>
    </Container>
  );
};
