import React, { useState } from "react";
import { Group, Checkbox, TextInput, Select, Stepper } from "@mantine/core";

import tw from "twin.macro";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";

import { useForm } from "@mantine/form";
const ModalButton = tw.button`my-4 mr-2 rounded-full bg-primary-500 py-4 font-bold text-gray-100 transition duration-300 w-[40%] hover:bg-primary-900 sm:(my-2 w-[20%])`;
const ContactTitle = tw.p`mb-8 font-bold text-lg`;
const HiddenButton = tw.button`hidden`;
const budgetOptions = [
  { label: "Less than $300", value: "300" },
  { label: "$300-$500", value: "300-500" },
  { label: "$500-$750", value: "500-750" },
  { label: "$750-$1250", value: "750-1250" },
  { label: "$1250-2000", value: "1250-2000" },
  { label: "$2000-$5000", value: "2000-5000" },
  {
    label:
      "None, I don’t need a website but I’m interested in consulting/coaching only",
    value: "none",
  },
  {
    label:
      "I’m not interested in any services from WeBuilder.com, please don’e contact me",
    value: "not interested",
  },
  {
    label: "Prefer not to say (though I have at least $500 to spend)",
    value: "prefer not to say",
  },
];

function FormStepper() {
  useCalendlyEventListener({
    onProfilePageViewed: () => console.log("onProfilePageViewed"),
    onDateAndTimeSelected: () => {
      // document.forms["formspree"].submit((e) => e.preventDefault());
    },
    onEventTypeViewed: (e) => {},
    onEventScheduled: (e) => {
      console.log("onEventScheduled", e);
      document.forms["formspree"].submit((e) => e.preventDefault());
    },
  });
  const [active, setActive] = useState(0);

  const form = useForm({
    initialValues: {
      needNewWebsite: "",
      startup: "",
      budget: "",
      website: "",
      contact: "",
      noContact: "",
      firstName: "",
      phone: "",
      email: "",
    },

    validate: (values) => {
      if (active === 0) {
        return {
          needNewWebsite: !values.needNewWebsite
            ? "Please select one of the options"
            : null,
          startup: !values.startup ? "Please select one of the options" : null,
          budget: !values.budget ? "Please select one of the options" : null,
        };
      }

      if (active === 1) {
        return {
          firstName: !values.firstName ? "Please enter your name" : null,
          // phone: /^\d+$/.test(values.phone) ? null : "Invalid phone number",
          email: /^\S+@\S+$/.test(values.email) ? null : "Invalid email",
        };
      }

      if (active === 2) {
        // return {
        //   contact:
        //     !values.contact || !values.noContact
        //       ? "Please select one of the options"
        //       : null,
        //   noContact:
        //     !values.contact || !values.noContact
        //       ? "Please select one of the options"
        //       : null,
        // };
      }

      return {};
    },
  });

  const nextStep = () =>
    setActive((current) => {
      if (form.validate().hasErrors) {
        return current;
      }

      return current < 3 ? current + 1 : current;
    });

  const prevStep = () =>
    setActive((current) => (current > 0 ? current - 1 : current));

  return (
    <>
      <Stepper active={active} breakpoint="sm">
        <Stepper.Step label="First step">
          <Select
            withAsterisk
            {...form.getInputProps("needNewWebsite")}
            label="Do you need a new website? Or have a website that needs updating, improvement etc?"
            placeholder="Pick one"
            searchable
            value={form.values.needNewWebsite}
            maxDropdownHeight={280}
            data={[
              { value: "yes", label: "Yes" },
              { value: "no", label: "No" },
            ]}
          />
          {form.values.needNewWebsite === "yes" && (
            <TextInput
              label="Website Name (if you have one)"
              placeholder="https://www.yourwebsite.com"
              value={form.values.website}
              {...form.getInputProps("website")}
            />
          )}
          <Select
            withAsterisk
            {...form.getInputProps("startup")}
            label="Are you building a startup?"
            placeholder="Pick one"
            searchable
            value={form.values.startup}
            maxDropdownHeight={280}
            data={[
              { value: "yes", label: "Yes" },
              { value: "no", label: "No" },
            ]}
          />
          <Select
            withAsterisk
            {...form.getInputProps("budget")}
            label="Select the budget you have to spend on a website in the next month (box for each number):"
            placeholder="Pick an option please"
            searchable
            value={form.values.budget}
            maxDropdownHeight={280}
            data={budgetOptions}
          />
        </Stepper.Step>

        <Stepper.Step label="Second step">
          <TextInput
            withAsterisk
            label="First Name"
            placeholder="Enter name here"
            {...form.getInputProps("firstName")}
          />
          <TextInput
            withAsterisk
            label="Email"
            placeholder="name@email.com"
            {...form.getInputProps("email")}
          />
          <TextInput
            label="Phone Number"
            placeholder="999-999-9999"
            {...form.getInputProps("phone")}
          />
        </Stepper.Step>

        <Stepper.Step label="Final step">
          <ContactTitle>
            Thank you for your information, you have indicated that you would
            like to be contacted by us in relation to your interest in
            potentially doing business with us; we just wanted to make sure that
            you indeed have a desire to be contacted by us for our services. If
            so please select “Yes” if not, and somehow we are mistaken, just
            select “no” and we won’t bother you again, thanks!
          </ContactTitle>
          <Checkbox
            label=" Yes! Please contact me (Set up a 10-15min free consultation during which we’ll discuss your business/need and assess your situation and see how we are best able to help you"
            size="md"
            value={form.values.contact}
            {...form.getInputProps("contact")}
          />
          <Checkbox
            label="No, please don’t contact me"
            size="md"
            value={form.values.noContact}
            {...form.getInputProps("noContact")}
          />
        </Stepper.Step>
        <Stepper.Completed>
          {/* Completed! Form values:
          <Code block mt="xl">
            {JSON.stringify(form.values, null, 2)}
          </Code> */}
          <InlineWidget
            url="https://calendly.com/founderteam/free-30-minute-consultation"
            prefill={{
              email: form.values.email,
              name: form.values.firstName,
            }}
          />
        </Stepper.Completed>
      </Stepper>

      <Group position="right" mt="xl">
        {active !== 0 && (
          <ModalButton variant="default" onClick={prevStep}>
            Back
          </ModalButton>
        )}
        {active !== 3 && (
          <ModalButton onClick={nextStep}>Next step</ModalButton>
        )}
      </Group>
      <form
        action="https://formspree.io/f/mrgvbeel"
        method="POST"
        name="formspree"
      >
        <input
          type="hidden"
          name="needNewWebsite"
          value={form.values.needNewWebsite}
        />
        <input type="hidden" name="startup" value={form.values.startup} />
        <input type="hidden" name="budget" value={form.values.budget} />
        <input type="hidden" name="contact" value={form.values.contact} />
        <input type="hidden" name="noContact" value={form.values.noContact} />
        <input type="hidden" name="website" value={form.values.website} />
        <input type="hidden" name="firstName" value={form.values.firstName} />
        <input type="hidden" name="phone" value={form.values.phone} />
        <input type="hidden" name="email" value={form.values.email} />
        <HiddenButton type="submit" id="main-submit"></HiddenButton>
      </form>
    </>
  );
}

export default FormStepper;
