/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import {
  Container as ContainerBase,
  ContentWithPaddingXl,
} from "components/misc/Layouts";
import { SectionDescription } from "components/misc/Typography";

const Container = tw(
  ContainerBase
)`my-8 -mx-8 bg-primary-900 px-8 text-gray-100 lg:my-10`;
const HeadingContainer = tw.div``;
const Heading = tw(SectionHeading)`sm:text-3xl md:text-4xl lg:text-5xl`;
const Subheading = tw(SubheadingBase)`text-center text-gray-100`;
const Description = tw(
  SectionDescription
)`mx-auto max-w-screen-md text-center text-gray-400`;

const StatsContainer = tw.div`mx-auto mt-8 flex max-w-screen-md flex-col flex-wrap items-center justify-center justify-between sm:flex-row`;
const Stat = tw.div`flex flex-col p-4 text-center tracking-wide`;
const StatKey = tw.div`font-medium text-xl`;
const StatValue = tw.div`font-black text-4xl sm:text-3xl md:text-4xl lg:text-5xl`;

export default ({
  subheading = "",
  heading = "Over 9000 Projects Completed",
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  stats = [
    {
      key: "Clients",
      value: "2500+",
    },
    {
      key: "Revenue",
      value: "$100M+",
    },
    {
      key: "Employees",
      value: "150+",
    },
  ],
}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          {description && <Description>{description}</Description>}
        </HeadingContainer>
        <StatsContainer>
          {stats.map((stat, index) => (
            <Stat key={index}>
              <StatValue>{stat.value}</StatValue>
              <StatKey>{stat.key}</StatKey>
            </Stat>
          ))}
        </StatsContainer>
      </ContentWithPaddingXl>
    </Container>
  );
};
