/* eslint-disable import/no-anonymous-default-export */

import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { useForm, ValidationError } from "@formspree/react";
import { Checkbox } from "@mantine/core";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/email-illustration.svg";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
const Container = tw.div`relative`;
const TwoColumn = tw.div`mx-auto flex max-w-screen-xl flex-col justify-between py-20 md:(flex-row py-24)`;
const Column = tw.div`mx-auto w-full max-w-md md:(mx-0 max-w-none)`;
const ImageColumn = tw(Column)`h-80 flex-shrink-0 md:(h-auto w-5/12)`;
const TextColumn = styled(Column)((props) => [
  tw`mt-16 md:(mt-0 w-7/12)`,
  props.textOnLeft
    ? tw`md:(order-first mr-12) lg:mr-16`
    : tw`md:(order-last ml-12) lg:ml-16`,
]);

const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`h-full rounded bg-contain bg-center bg-no-repeat`,
]);
const TextContent = tw.div`text-center md:text-left lg:py-8`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 text-left text-center font-black text-3xl leading-tight sm:text-4xl md:text-left lg:text-5xl`;
const Description = tw.p`mt-4 text-center font-bold leading-relaxed text-[#383C42] md:(text-left text-base) lg:text-lg`;

const Form = tw.form`mx-auto mt-8 flex max-w-sm flex-col text-sm md:(mx-0 mt-10)`;
const Input = tw.input`mt-6 border-b-2 bg-gray-primary py-3 font-medium transition duration-300 hocus:border-primary-500 first:mt-0 focus:outline-none`;
const Textarea = styled(Input).attrs({ as: "textarea" })`
  ${tw`mb-6 h-20 bg-gray-primary`}
`;

// const SubmitButtons = styled(PrimaryButtonBase)`mt-8 inline-block`;
const SubmitButton = styled(PrimaryButtonBase)((props) => [
  tw`mx-auto mt-8 inline-block w-full rounded-full text-center text-sm md:(mx-0 mt-8)`,
  props.buttonRounded && tw`rounded-full`,
]);

export default ({
  subheading = "Contact Us",
  heading = (
    <>
      Feel free to <span tw="text-primary-500">get in touch</span>
      <wbr /> with us.
    </>
  ),
  description = "Whether you're looking for more information about our services, need assistance with an order, or just want to share your thoughts with us, we're here to listen. Use the form below to send us a message and we'll get back to you as soon as we can.",
  submitButtonText = "Send",
  formAction = "#",
  formMethod = "get",
  textOnLeft = true,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  const [value, setValue] = useState();
  const [state, handleSubmit] = useForm("mrgvbeel");
  if (state.succeeded) {
    return (
      <Container>
        <TwoColumn>
          <ImageColumn>
            <Image imageSrc={EmailIllustrationSrc} />
          </ImageColumn>
          <TextColumn textOnLeft={textOnLeft}>
            <TextContent>
              {subheading && <Subheading>{subheading}</Subheading>}
              <Heading>Thanks for reaching out!</Heading>
              {description && <Description>{description}</Description>}
            </TextContent>
          </TextColumn>
        </TwoColumn>
      </Container>
    );
  }
  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
            <Form onSubmit={handleSubmit}>
              <Input
                type="email"
                name="email"
                placeholder="Your Email Address"
              />
              <ValidationError
                prefix="Email"
                field="email"
                errors={state.errors}
              />

              <Input type="text" name="name" placeholder="Full Name" />
              <Input type="text" name="subject" placeholder="Subject" />
              <Textarea name="message" placeholder="Your Message Here" />
              <ValidationError
                prefix="Message"
                field="message"
                errors={state.errors}
              />
              <PhoneInput
                placeholder="Enter phone number"
                value={value}
                onChange={setValue}
              />
              <br />
              <Checkbox
                color="grape"
                label="Opt out of mailing list?"
                type="checkbox"
                value="No"
              />

              <SubmitButton type="submit" disabled={state.submitting}>
                {submitButtonText}
              </SubmitButton>
            </Form>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
