/* eslint-disable import/no-anonymous-default-export */

import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";

import LogoImage from "images/logo.svg";
import { ReactComponent as FacebookIcon } from "images/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "images/twitter-icon.svg";
import { ReactComponent as YoutubeIcon } from "images/youtube-icon.svg";

const Container = tw.div`relative -mx-8 -mb-8 bg-gray-200 px-8 py-20 text-gray-700 lg:py-24`;
const Content = tw.div`relative z-10 mx-auto max-w-screen-xl`;
const SixColumns = tw.div`-mt-12 flex flex-wrap justify-center text-center sm:(justify-start text-left) md:justify-between`;

const Column = tw.div`mt-12 px-4 sm:(w-1/4 px-0) md:w-auto`;

const ColumnHeading = tw.h5`font-bold uppercase`;

const LinkList = tw.ul`mt-6 font-medium text-sm`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-transparent border-b-2 pb-1 transition duration-300 hocus:border-gray-700`;

const SubscribeNewsletterColumn = tw(
  Column
)`mt-20 w-full! text-center lg:(mt-12 w-auto! text-left)`;
const SubscribeNewsletterContainer = tw.div`mx-auto max-w-sm lg:mx-0`;
const SubscribeText = tw.p`mt-2 font-medium text-sm text-gray-600 lg:mt-6`;
const SubscribeForm = tw.form`mx-auto mt-4 max-w-xs text-sm sm:(mx-0 flex max-w-none) lg:mt-6`;
const Input = tw.input`w-full rounded border-2 border-gray-400 bg-gray-300 px-6 py-3 transition duration-300 hover:border-primary-500 focus:outline-none sm:(rounded-r-none border-r-0)`;
const SubscribeButton = tw(
  PrimaryButtonBase
)`mt-4 w-full rounded px-8 py-3 sm:(mt-0 w-auto rounded-l-none)`;

const Divider = tw.div`my-16 w-full border-gray-300 border-b-2`;

const ThreeColRow = tw.div`flex flex-col items-center justify-between md:flex-row`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`ml-2 font-black text-xl text-gray-800 tracking-wider`;

const CopywrightNotice = tw.p`mt-8 text-center font-medium text-sm text-gray-500 sm:text-base md:mt-0`;

const SocialLinksContainer = tw.div`mt-8 flex md:mt-0`;
const SocialLink = styled.a`
  ${tw`mr-4 cursor-pointer rounded-full bg-gray-900 p-2 text-gray-100 transition duration-300 last:mr-0 hover:bg-gray-700`}
  svg {
    ${tw`h-4 w-4`}
  }
`;

export default () => {
  return (
    <Container>
      <Content>
        <SixColumns>
          <Column>
            <ColumnHeading>Main</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="#">Blog</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#">FAQs</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#">Support</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#">About Us</Link>
              </LinkListItem>
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>Product</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="#">Log In</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#">Personal</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#">Business</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#">Team</Link>
              </LinkListItem>
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>Press</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="#">Logos</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#">Events</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#">Stories</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#">Office</Link>
              </LinkListItem>
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>Legal</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="#">GDPR</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#">Privacy Policy</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#">Terms of Service</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#">Disclaimer</Link>
              </LinkListItem>
            </LinkList>
          </Column>
          <SubscribeNewsletterColumn>
            <SubscribeNewsletterContainer>
              <ColumnHeading>Subscribe to our Newsletter</ColumnHeading>
              <SubscribeText>
                We deliver high quality blog posts written by professionals
                weekly. And we promise no spam.
              </SubscribeText>
              <SubscribeForm method="get" action="#">
                <Input type="email" placeholder="Your Email Address" />
                <SubscribeButton type="submit">Subscribe</SubscribeButton>
              </SubscribeForm>
            </SubscribeNewsletterContainer>
          </SubscribeNewsletterColumn>
        </SixColumns>
        <Divider />
        <ThreeColRow>
          <LogoContainer>
            <LogoImg src={LogoImage} />
            <LogoText>Treact Inc.</LogoText>
          </LogoContainer>
          <CopywrightNotice>
            &copy; 2018 Treact Inc. All Rights Reserved.
          </CopywrightNotice>
          <SocialLinksContainer>
            <SocialLink href="https://facebook.com">
              <FacebookIcon />
            </SocialLink>
            <SocialLink href="https://twitter.com">
              <TwitterIcon />
            </SocialLink>
            <SocialLink href="https://youtube.com">
              <YoutubeIcon />
            </SocialLink>
          </SocialLinksContainer>
        </ThreeColRow>
      </Content>
    </Container>
  );
};
