/* eslint-disable no-unused-vars */
/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";
import { motion } from "framer-motion";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";

// import defaultCardImage from "../../images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";
import { SectionDescription as DescriptionBase } from "components/misc/Typography";

import SupportIconImage from "../../images/support-icon.svg";
import ShieldIconImage from "../../images/shield-icon.svg";
import CustomizeIconImage from "../../images/customize-icon.svg";
import FastIconImage from "../../images/fast-icon.svg";
import ReliableIconImage from "../../images/reliable-icon.svg";
import SimpleIconImage from "../../images/simple-icon.svg";
import { components } from "ComponentRenderer.js";
import {
  Container,
  // Content2Xl,
  ContentWithVerticalPadding,
} from "components/misc/Layouts";
// const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`mx-auto flex max-w-screen-xl flex-col flex-wrap items-center py-20 md:(flex-row items-stretch justify-center py-24)`}
`;
const Heading = tw(SectionHeading)`w-full`;
const SectionContainer = tw(ContentWithVerticalPadding)``;

const SectionDescription = tw(
  DescriptionBase
)`mx-auto max-w-4xl text-center font-bold text-[#383C42]`;

const PreviewCards = tw.div`-mr-12 flex w-full flex-wrap xl:flex-nowrap`;
const PreviewCardContainer = tw.div`mx-auto mt-24 w-full max-w-lg pr-12 md:(mx-0 w-1/2)`;
const PreviewCard = tw(motion.a)`block rounded-lg shadow-raised`;
const PreviewCardImageContainer = tw.div`rounded-t-lg border-0 border-b-0`;
const PreviewCardImage = styled(motion.div)`
  ${(props) =>
    css`
      background-image: url("${props.$imageSrc}");
    `}
  ${tw`h-128 bg-cover bg-left-top md:h-144`}
`;
const PreviewButton = tw(
  PrimaryButtonBase
)`w-full rounded-b-lg rounded-t-none py-5 font-semibold`;
const previewImageAnimationVariants = {
  rest: {
    backgroundPositionY: "0%",
  },
  hover: {
    backgroundPositionY: "100%",
    transition: { type: "tween", ease: "linear", duration: 5 },
  },
};
const Column = styled.div`
  ${tw`flex px-6 md:w-1/2 lg:w-1/3`}
`;

const Card = styled.div`
  ${tw`mx-auto mt-12 flex max-w-xs flex-col items-center rounded-lg border-2 border-primary-500 border-dashed px-6 py-10`}
  .imageContainer {
    ${tw`relative flex-shrink-0 rounded-full border-2 border-primary-500 p-6 text-center`}
    img {
      ${tw`h-8 w-8`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl text-primary-500 leading-none`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 translate-x-32 translate-y-48 transform opacity-25`}
`;

export default ({ landingPages = components.landingPages }) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */
  const noOfLandingPages = Object.keys(landingPages).length;

  const cards = [
    {
      imageSrc: ShieldIconImage,
      title: "Ads Management",
      description:
        "We create and manage ads that you need, from creation to deployment. Lorem ipsum donor sit amet consicou.",
    },
    { imageSrc: SupportIconImage, title: "Video Marketing" },
    { imageSrc: CustomizeIconImage, title: "Customer Relation" },
    { imageSrc: ReliableIconImage, title: "Product Outreach" },
    { imageSrc: FastIconImage, title: "PR Campaign" },
    { imageSrc: SimpleIconImage, title: "Product Expansion" },
  ];

  return (
    <Container>
      {/* <ThreeColumnContainer>
        <Heading>
          Our Professional <span tw="text-primary-500">Services</span>
        </Heading>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description ||
                    "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer> */}
      <ThreeColumnContainer id="landingPageDemos">
        {/* <SectionHeading>Landing Pages</SectionHeading> */}
        <Heading>
          Pick from one of our {noOfLandingPages} Templates{" "}
          <span tw="text-primary-500">Services</span>
        </Heading>
        <SectionDescription>
          We have {noOfLandingPages} premade landing pages. Click on the "View
          Live Demo" button to see them in action. Customizing or Creating your
          own custom landing page is really simple by using our UI components.
        </SectionDescription>
        <PreviewCards>
          {Object.entries(landingPages).map(([pageName, page], index) => (
            <PreviewCardContainer key={index}>
              <PreviewCard
                initial="rest"
                animate="rest"
                whileHover="hover"
                href={page.url}
                target="_blank"
              >
                <PreviewCardImageContainer>
                  <PreviewCardImage
                    transition={{ type: "tween" }}
                    variants={previewImageAnimationVariants}
                    $imageSrc={page.imageSrc}
                  />
                </PreviewCardImageContainer>
                <PreviewButton>View Live Demo</PreviewButton>
              </PreviewCard>
            </PreviewCardContainer>
          ))}
        </PreviewCards>
      </ThreeColumnContainer>
    </Container>
  );
};
