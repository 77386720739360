/* eslint-disable import/no-anonymous-default-export */

import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-6.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";

const HeaderContainer = tw.div`flex w-full flex-col items-center`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const PlanDurationSwitcher = tw.div`mt-8 block w-full max-w-xs rounded-full border-2 px-1 py-1 sm:(inline-block w-auto)`;
const SwitchButton = styled.button`
  ${tw`w-1/2 rounded-full px-4 py-3 font-bold text-sm text-gray-700 transition duration-300 focus:outline-none sm:(w-32 px-8)`}
  ${(props) => props.active && tw`bg-primary-500 text-gray-100`}
`;

const PlansContainer = tw.div`relative flex flex-col items-center justify-center md:(flex-row items-start)`;
const Plan = styled.div`
  ${tw`relative mt-16 flex w-full max-w-72 flex-col rounded-lg bg-white px-8 text-center text-gray-900 shadow-raised md:(mr-12 last:mr-0)`}

  ${(props) =>
    props.featured &&
    css`
      ${tw`border-2 border-gray-200 shadow-none`}
    `}
`;

const PlanHeader = styled.div`
  ${tw`-mx-8 flex flex-col rounded-t-lg bg-gray-100 py-8 leading-relaxed`}
  .name {
    ${tw`font-bold text-xl`}
  }
  .price {
    ${tw`my-1 font-bold text-4xl sm:text-5xl`}
  }
  .slash {
    ${tw`text-xl text-gray-500`}
  }
  .duration {
    ${tw`font-medium text-gray-500 lowercase tracking-widest`}
  }
  .mainFeature {
    ${tw`font-medium text-gray-500 text-sm tracking-wide`}
  }
`;
const PlanFeatures = styled.div`
  ${tw`-mx-8 flex flex-1 flex-col px-8 py-8 text-sm`}
  .feature {
    ${tw`mt-5 font-semibold text-gray-500 first:mt-0`}
  }
`;

const PlanAction = tw.div`px-4 pb-8`;
const BuyNowButton = styled(PrimaryButtonBase)`
  ${tw`w-full transform rounded-full py-4 text-sm tracking-wider hocus:(translate-x-px -translate-y-px) hover:shadow-xl focus:shadow-outline`}
`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none absolute left-0 bottom-0 -z-20 h-64 w-64 -translate-x-2/3 -translate-y-1/2 transform opacity-25`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none absolute right-0 top-0 -z-20 h-64 w-64 translate-x-2/3 translate-y-1/2 transform fill-current text-teal-300 opacity-25`}
`;

export default ({
  subheading = "Pricing",
  heading = "Flexible Plans.",
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  plans = null,
  primaryButtonText = "Buy Now",
  planDurations = [
    {
      text: "Month",
      switcherText: "Monthly",
    },
    {
      text: "Year",
      switcherText: "Yearly",
    },
  ],
}) => {
  const defaultPlans = [
    {
      name: "Free Plan",
      durationPrices: ["$0", "$0"],
      mainFeature: "For Personal Blogs",
      features: [
        "30 Templates",
        "7 Landing Pages",
        "12 Internal Pages",
        "Basic Assistance",
      ],
    },
    {
      name: "Pro Plan",
      durationPrices: ["$49", "$499"],
      mainFeature: "Suited for Production Websites",
      features: [
        "60 Templates",
        "8 Landing Pages",
        "22 Internal Pages",
        "Priority Assistance",
        "Lifetime Updates",
      ],
      featured: true,
    },
  ];

  if (!plans) plans = defaultPlans;

  const [activeDurationIndex, setActiveDurationIndex] = useState(0);

  return (
    <Container>
      <ContentWithPaddingXl>
        <HeaderContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          {description && <Description>{description}</Description>}
          <PlanDurationSwitcher>
            {planDurations.map((planDuration, index) => (
              <SwitchButton
                active={activeDurationIndex === index}
                key={index}
                onClick={() => setActiveDurationIndex(index)}
              >
                {planDuration.switcherText}
              </SwitchButton>
            ))}
          </PlanDurationSwitcher>
        </HeaderContainer>
        <PlansContainer>
          {plans.map((plan, index) => (
            <Plan key={index} featured={plan.featured}>
              <PlanHeader>
                <span className="priceAndDuration">
                  <span className="price">
                    {plan.durationPrices[activeDurationIndex]}
                  </span>
                  <span className="slash"> / </span>
                  <span className="duration">
                    {planDurations[activeDurationIndex].text}
                  </span>
                </span>
                <span className="name">{plan.name}</span>
                <span className="mainFeature">{plan.mainFeature}</span>
              </PlanHeader>
              <PlanFeatures>
                {plan.features.map((feature, index) => (
                  <span key={index} className="feature">
                    {feature}
                  </span>
                ))}
              </PlanFeatures>
              <PlanAction>
                <BuyNowButton>{primaryButtonText}</BuyNowButton>
              </PlanAction>
            </Plan>
          ))}
        </PlansContainer>
      </ContentWithPaddingXl>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
