/* eslint-disable no-unused-vars */
/* eslint-disable import/no-anonymous-default-export */
import React, { useState, Fragment, useRef } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";

import { Dialog, Transition } from "@headlessui/react";
import {
  Modal,
  Button,
  Group,
  Checkbox,
  Label,
  TextInput,
  Select,
  Stepper,
  Code,
  MediaQuery,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import FormStepper from "helpers/FormStepper.js";
//eslint-disable-next-line
import { css } from "styled-components/macro";
// import Button from "react-bootstrap/Button";
// import Modal from "react-bootstrap/Modal";
import Header from "../headers/light.js";
// import "bootstrap/dist/css/bootstrap.min.css";
// import Form from "react-bootstrap/Form";
// import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import DesignIllustration from "../../images/design-illustration-2.svg";
import CustomersLogoStripImage from "../../images/customers-logo-strip.png";
const Container = tw.div`relative`;
const CheckboxContainer = tw.div`mr-2 flex`;
const FormContainer = tw.div`mx-auto flex flex-col items-center justify-center`;
const TwoColumn = tw.div`mx-auto flex max-w-screen-sm flex-col items-center py-20 text-center md:(max-w-screen-lg py-24)`;
const LeftColumn = tw.div`relative mx-auto max-w-lg text-center lg:(mr-32 w-5/12 max-w-none text-center)`;
const RightColumn = tw.div`relative mt-12 flex-1 flex flex-col justify-center lg:(mt-0 self-end)`;
const ModalButton = tw.button`my-4 mr-2 rounded-full bg-primary-500 py-4 font-bold text-gray-100 transition duration-300 w-[20%] hover:bg-primary-900 sm:my-2`;
const Heading = tw.h1`max-w-screen-md font-bold text-secondary-700 leading-tight text-[2.5rem] xl:(max-w-screen-xl text-[3.0rem])`;
const Paragraph = tw.p`my-5 max-w-screen-sm font-bold text-sm text-[#383C42] lg:(my-8 max-w-screen-lg text-xl)`;
const Actions = styled.div`
  ${tw`relative mx-auto flex h-20 items-center justify-center text-center lg:mx-0`}
  button {
    ${tw`my-4 mr-2 w-48 rounded-full bg-primary-500 py-4 font-medium font-bold text-gray-100 transition duration-300 hover:bg-primary-900 focus:outline-none sm:(absolute my-2 leading-none) md:w-72`}
  }
  }
`;

const IllustrationContainer = tw.div`flex items-center justify-center lg:justify-end`;

// Random Decorator Blobs (shapes that you see in background)
// const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
//   ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
// `;

const CustomersLogoStrip = styled.div`
  ${tw`mt-12 lg:mt-20`}
  p {
    ${tw`font-bold text-sm text-gray-500 uppercase tracking-wider lg:text-xs`}
  }
  img {
    ${tw`mt-4 w-full opacity-50 lg:pr-16 xl:pr-32`}
  }
`;

const budgetOptions = [
  { label: "Less than $300", value: "300" },
  { label: "$300-$500", value: "300-500" },
  { label: "$500-$750", value: "500-750" },
  { label: "$750-$1250", value: "750-1250" },
  { label: "$1250-2000", value: "1250-2000" },
  { label: "$2000-$5000", value: "2000-5000" },
  {
    label:
      "None, I don’t need a website but I’m interested in consulting/coaching only",
    value: "none",
  },
  {
    label:
      "I’m not interested in any services from WeBuilder.com, please don’e contact me",
    value: "not interested",
  },
  {
    label: "Prefer not to say (though I have at least $500 to spend)",
    value: "prefer not to say",
  },
];

export default ({ roundedHeaderButton }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleModal = () => {
    setIsOpen(!isOpen);
  };

  const handleForm = (e) => {
    e.preventDefault();

    // setFormStep(formStep + 1);
  };

  useCalendlyEventListener({
    onProfilePageViewed: () => console.log("onProfilePageViewed"),
    onDateAndTimeSelected: () => console.log("onDateAndTimeSelected"),
    onEventTypeViewed: () => console.log("onEventTypeViewed"),
    onEventScheduled: (e) => {
      console.log("onEventScheduled", e);
      handleModal();
    },
  });
  return (
    <>
      <MediaQuery smallerThan="md" styles={{ display: "none" }}>
        <Modal
          opened={isOpen}
          onClose={handleModal}
          size="60%"
          centered
          transition="fade"
          transitionDuration={600}
          transitionTimingFunction="ease"
        >
          <FormStepper />
        </Modal>
      </MediaQuery>
      <MediaQuery largerThan={"md"} styles={{ display: "none" }}>
        <Modal
          opened={isOpen}
          onClose={handleModal}
          size="90%"
          centered
          transition="fade"
          transitionDuration={600}
          transitionTimingFunction="ease"
        >
          <FormStepper />
        </Modal>
      </MediaQuery>

      <Container>
        <TwoColumn>
          {/* <LeftColumn> */}
          <Heading>Beautiful Custom Websites</Heading>
          <Heading tw="text-center text-primary-500">for you.</Heading>

          <Paragraph>
            We help early stage founders and small businesses go from concept
            stage, to having an effectual channel for doing business, quickly,
            affordably and effectively by helping build and design your website
            and outreach system in a unique way; custom designed for your
            specific business model by startup founders who have done what
            you’re trying to do now, have run in to many of the same road
            blocks, and can help you in ways that big agencies can’t, for just a
            fraction of the cost.
          </Paragraph>
          <Paragraph>
            Let us help you sidestep the potholes that we have already stepped
            in and learned the hard way, how to recover when we could’ve been
            warned and coached to avoid so many of the roadblocks every startup
            and small business comes across, that’s why we’re here. We took a
            less expensive, higher value version of the products and services
            our competitors are offering their clients, then we added very
            specific services that are inherently necessary for almost all
            businesses in their early stages, gave access to those services in
            clever ways, that give you an edge, right from the beginning.
          </Paragraph>
          <Paragraph>
            So whether you need a website stat, want help developing an idea,
            need some coaching/consulting in a specific area or already have a
            site but need help getting it working properly, getting it to look
            and function the way you want it to without having to pay thousands
            for an inferior website, with a larger agency - please set an
            appointment on our Calendly and we’ll give you a call .
          </Paragraph>
          <Actions>
            <button onClick={handleModal}>Get Started</button>{" "}
          </Actions>
          {/* <CustomersLogoStrip>
              <p>Our TRUSTED Customers</p>
              <img src={CustomersLogoStripImage} alt="Our Customers" />
            </CustomersLogoStrip> */}
          {/* </LeftColumn> */}
          {/* <RightColumn>
            <IllustrationContainer>
              <img
                tw="min-w-0 w-full max-w-lg xl:max-w-3xl"
                src="https://img.freepik.com/free-vector/group-people-working-together_52683-28615.jpg?w=1380&t=st=1673243992~exp=1673244592~hmac=61a36096d5d900d0ac7ccc25e9529f448863565129ee0f9ebb844c81ba6640e8"
                alt="Design Illustration of people working together"
              />
              <img
                tw="min-w-0 w-full max-w-lg xl:max-w-3xl"
                src="https://img.freepik.com/free-vector/people-putting-puzzle-pieces-together_52683-28610.jpg?w=1060&t=st=1673244080~exp=1673244680~hmac=e28e0c313e998fdac7a67e92b4dad625b34edf32fbb434ba26e1e5f2f7ef9bb3"
                alt="Design Illustration of people working together"
              />
            </IllustrationContainer>
          </RightColumn> */}
        </TwoColumn>
        {/* <DecoratorBlob1 /> */}
      </Container>
    </>
  );
};
