/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
// import { ReactComponent as SvgDecoratorBlob } from "images/svg-decorator-blob-6.svg";

const HeaderContainer = tw.div`mt-10 flex w-full flex-col items-center`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const PlansContainer = tw.div`relative flex flex-col items-center justify-between lg:items-stretch`;
const SubscriptionContainer = tw.div`relative flex flex-col items-center justify-around lg:flex-row`;
const PlanHeading = tw.h2`mt-16 text-center font-black text-primary-500 text-lg tracking-wide lg:text-2xl`;
const PlanSubheading = tw.h5`mb-4 text-center font-black text-black text-lg lg:text-2xl`;

const Plan = styled.div`
  ${tw`relative mt-8 flex w-full max-w-sm flex-col rounded-lg bg-white px-8 pt-2 text-center text-gray-900 shadow lg:(mr-8 last:mr-0)`}
  .planHighlight {
    ${tw`absolute inset-x-0 top-0 h-2 rounded-t-lg`}
  }

  ${(props) =>
    props.featured &&
    css`
      background: rgb(100,21,255);
      background: linear-gradient(135deg, rgba(100,21,255,1) 0%, rgba(128,64,252,1) 100%);
background: rgb(85,60,154);
background: linear-gradient(135deg, rgba(85,60,154,1) 0%, rgba(128,90,213,1) 100%);
background: rgb(76,81,191);
background: linear-gradient(135deg, rgba(76,81,191,1) 0%, rgba(102,126,234,1) 100%);
      ${tw`bg-primary-500 text-gray-100`}
      .planHighlight {
        ${tw`hidden`}
      }
      .duration {
        ${tw`font-bold text-gray-200!`}
      }
      ${PlanFeatures} {
        ${tw`border-indigo-500`}
      }
      .feature:not(.mainFeature) {
        ${tw`font-bold text-gray-100!`}
      }
      ${BuyNowButton} {
        ${tw`bg-gray-100 text-primary-500 hocus:(bg-gray-300 text-primary-800)`}
    `}
`;

const PlanHeader = styled.div`
  ${tw`flex flex-col py-8 uppercase leading-relaxed`}
  .name {
    ${tw`font-bold text-xl`}
  }
  .price {
    ${tw`my-1 font-bold text-4xl sm:text-5xl`}
  }
  .duration {
    ${tw`font-bold text-gray-500 tracking-widest`}
  }
`;
const PlanFeatures = styled.div`
  ${tw`-mx-8 flex flex-1 flex-col border-t-2 border-b-2 px-8 py-8`}
  .feature {
    ${tw`mt-5 font-medium first:mt-0`}
    &:not(.mainFeature) {
      ${tw`font-bold text-black`}
    }
  }
  .mainFeature {
    ${tw`font-bold text-xl tracking-wide`}
  }
`;

const PlanAction = tw.div`px-4 py-8 sm:px-8 xl:px-16`;
const BuyNowButton = styled(PrimaryButtonBase)`
  ${tw`w-full transform rounded-full py-4 text-sm uppercase tracking-wider hocus:(translate-x-px -translate-y-px) hover:shadow-xl focus:shadow-outline`}
`;

// const DecoratorBlob = styled(SvgDecoratorBlob)`
//   ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-25 transform -translate-x-1/2 translate-y-1/2`}
// `;

export default ({
  subheading = "Pricing",
  heading = "Flexible Plans.",
  description = "Affordable prices for top-quality products and services",
  plans = null,
  upfrontPlan = null,
  primaryButtonText = "Purchase",
}) => {
  const defaultPlans = [
    {
      name: "Foundation",
      price: "$399",
      duration: "Monthly",
      mainFeature: "Suited for Personal Use",
      features: [
        "Unlimited edits",
        "Up to 5 Pages",
        "1 items from a la carte services",
      ],
      paymentLink: "https://buy.stripe.com/aEUaGF9l45yH3lu9AJ",
    },
    {
      name: "JumpStart",
      price: "$849",
      duration: "Monthly",
      mainFeature: "Suited for Personal Business",
      features: [
        "Unlimited edits",
        "Up to 10 pages",
        "2 items from a la carte services",
      ],
      featured: true,
      paymentLink: "https://buy.stripe.com/5kA5mldBkgdl09ibIS",
    },
    {
      name: "LaunchPad",
      price: "$1349",
      duration: "Monthly",
      mainFeature: "Suited For Small Business",
      features: [
        "Unlimited Edits",
        "Up to 15 pages",
        "5 items from a la carte services",
        "Priority support",
      ],
      paymentLink: "https://buy.stripe.com/8wM4ihdBk5yH9JSbIT",
    },
  ];

  const upFrontPlans = [
    {
      name: "Foundation",
      price: "$999",
      duration: "",
      mainFeature: "Suited for Personal Use",
      features: [
        "No ongoing maintenance included",
        "Custom inexpensive, ongoing maintenance plans available",
        "Up to 5 pages",
      ],
      paymentLink: "https://buy.stripe.com/8wM6qpbtc6CL2hq28k",
    },
    {
      name: "JumpStart",
      price: "$1999",
      duration: "",
      mainFeature: "Suited for Personal Business",
      features: [
        "No ongoing maintenance included",
        "Custom inexpensive, ongoing maintenance plans available",
        "Up to 10 pages",
      ],
      featured: true,
      paymentLink: "https://buy.stripe.com/cN28yx7cW0en3lu9AN",
    },
    {
      name: "LaunchPad",
      price: "$2999",
      duration: "",
      mainFeature: "Suited For Small Business",
      features: [
        "No ongoing maintenance included",
        "Custom inexpensive, ongoing maintenance plans available",
        "Up to 15 pages",
        "5 items from the a la carte list",
      ],
      paymentLink: "https://buy.stripe.com/6oE3ed68Sgdl8FO14i",
    },
  ];

  if (!plans) plans = defaultPlans;
  if (!upfrontPlan) upfrontPlan = upFrontPlans;

  const highlightGradientsCss = [
    css`
      background: rgb(56, 178, 172);
      background: linear-gradient(
        115deg,
        rgba(56, 178, 172, 1) 0%,
        rgba(129, 230, 217, 1) 100%
      );
    `,
    css`
      background: rgb(56, 178, 172);
      background-image: linear-gradient(
        115deg,
        #6415ff,
        #7431ff,
        #8244ff,
        #8e56ff,
        #9a66ff
      );
    `,
    css`
      background: rgb(245, 101, 101);
      background: linear-gradient(
        115deg,
        rgba(245, 101, 101, 1) 0%,
        rgba(254, 178, 178, 1) 100%
      );
    `,
  ];

  return (
    <Container>
      <ContentWithPaddingXl>
        <HeaderContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          {description && <Description>{description}</Description>}
        </HeaderContainer>
        <SubscriptionContainer>
          <PlansContainer>
            <PlanHeading>Subscription</PlanHeading>
            <PlanSubheading>3 month minimum</PlanSubheading>
            {plans.map((plan, index) => (
              <Plan key={index} featured={plan.featured}>
                {!plan.featured && (
                  <div
                    className="planHighlight"
                    css={
                      highlightGradientsCss[
                        index % highlightGradientsCss.length
                      ]
                    }
                  />
                )}
                <PlanHeader>
                  <span className="name">{plan.name}</span>
                  <span className="price">{plan.price}</span>
                  <span className="duration">{plan.duration}</span>
                </PlanHeader>
                <PlanFeatures>
                  <span className="feature mainFeature">
                    {plan.mainFeature}
                  </span>
                  {plan.features.map((feature, index) => (
                    <span key={index} className="feature">
                      {feature}
                    </span>
                  ))}
                </PlanFeatures>
                <PlanAction>
                  <a href={plan.paymentLink} target="_blank" rel="noreferrer">
                    {" "}
                    <BuyNowButton
                      css={!plan.featured && highlightGradientsCss[index]}
                    >
                      {primaryButtonText}
                    </BuyNowButton>{" "}
                  </a>
                </PlanAction>
              </Plan>
            ))}
          </PlansContainer>

          <PlansContainer>
            <PlanHeading>Upfront</PlanHeading>
            <PlanSubheading>No monthly payments</PlanSubheading>
            {upfrontPlan.map((plan, index) => (
              <Plan key={index} featured={plan.featured}>
                {!plan.featured && (
                  <div
                    className="planHighlight"
                    css={
                      highlightGradientsCss[
                        index % highlightGradientsCss.length
                      ]
                    }
                  />
                )}
                <PlanHeader>
                  <span className="name">{plan.name}</span>
                  <span className="price">{plan.price}</span>
                  <span className="duration">{plan.duration}</span>
                </PlanHeader>
                <PlanFeatures>
                  <span className="feature mainFeature">
                    {plan.mainFeature}
                  </span>
                  {plan.features.map((feature, index) => (
                    <span key={index} className="feature">
                      {feature}
                    </span>
                  ))}
                </PlanFeatures>
                <PlanAction>
                  <a href={plan.paymentLink} target="_blank" rel="noreferrer">
                    {" "}
                    <BuyNowButton
                      css={!plan.featured && highlightGradientsCss[index]}
                    >
                      {primaryButtonText}
                    </BuyNowButton>{" "}
                  </a>
                </PlanAction>
              </Plan>
            ))}
          </PlansContainer>
        </SubscriptionContainer>
      </ContentWithPaddingXl>
    </Container>
  );
};
